<template>
  <highcharts
    id="chart-container"
    class="mt-2"
    :options="chartOptions"
    ref="barChart"
    :constructor-type="'chart'"
  ></highcharts>
</template>
<script>
// const jQuery = require("jquery");
import { Chart } from "highcharts-vue";
import EventBus from "@/commons/event_bus";
import {
  getXColumnsByStep,
  formatDataChartWithStepBarChartStep1,
  barChartOption,
  getReactionTagTooltipElStep1,
  getImageTooltipEl,
  getTooltipLineChartStep1,
  // lineChartOption
} from "@/commons/charts";
import {
  interactionMax,
  interactionAvg,
  roundDecimal,
  addPointZero,
} from "@/commons/helpers";
import rounded from "highcharts-rounded-corners";
import * as Highcharts from "highcharts";
rounded(Highcharts);
const NUMBER_BUTTON = 3;
export default {
  props: {
    changeStepChart: String,
    seriesData: Array,
    chartType: Object,
    currentTime: Number,
    dataReport: Object,
    imageURL: String,
    step: Number,
    part: Number,
    is25percent: Boolean,
    labelXaxis: Array,
    interactionType: Number,
    dataCognition: Object,
    youtubeRadioType: String,
    youtubeAudienceRatingReport: Array,
    project: Object,
    isStackBarParent: { type: Boolean, default: true },
    labelChart: Object,
    stepUnit: Number,
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    labelChart: {
      handler: function () {
        this.changeStepOrTypeChart(this.step);
        this.chart.series.forEach((serie) => {
          serie.update(
            this.chartOptions.series.find((e) => e.name == serie.name)
          );
        });
      },
      deep: true,
    },
    step: function (newValue) {
      this.changeStepOrTypeChart(newValue);
    },
    part: function (newValue, oldValue) {
      this.prevPart = oldValue;
      this.chartOptions.xAxis.min = (newValue - 1) * 16 + 0.1;
      this.chartOptions.xAxis.tickInterval = 2;
      this.chartOptions.xAxis.max = newValue * 16 + 0.1;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(
          this.step,
          (newValue - 1) * this.step * this.stepUnit,
          this.stepUnit
        ),
      };
      this.chartOptions.xAxis.plotLines[0].value = this.currentSelected;
      this.initSeriesData();
    },
    seriesData: function () {
      let vm = this;
      this.chartOptions.plotOptions.column.pointWidth = 10;
      this.chartOptions.xAxis.className = "null";
      this.chartOptions.xAxis.labels = {
        align: "left",
        useHTML: true,
        formatter: function () {
          return `<div>${
            this.value % 2 === 0 ? (this.value * vm.step) / 2 : ""
          }</div>`;
        },
        style: {
          color: "#999999",
          fontSize: "8px",
          fontWeight: "normal",
          fontFamily: "Avenir Next",
        },
      };
      this.chartOptions.xAxis.tickInterval = 2;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(
          this.step,
          (this.part - 1) * this.step * this.stepUnit,
          this.stepUnit
        ),
      };
      this.chartOptions.xAxis.min = (this.part - 1) * 16 + 0.1;
      this.chartOptions.xAxis.max = this.part * 16 + 0.1;
      this.tick = this.step;
      this.initSeriesData();

      this.$nextTick(() => {
        this.chart.redraw();
      });
    },
    currentTime: function (newValue) {
      if (!this.is25percent) {
        this.currentSelected = (newValue / this.step) * 2;
        let maxX = this.stepUnit;
        let part = Math.ceil(newValue / (maxX * this.step) * 2);
        if (part !== this.$props.part && part > 0) {
          EventBus.$emit("changePart", part);
        }
        this.updateLineChart();
      }
    },
    changeTime: function (newValue) {
      EventBus.$emit("changeCurrentTime", newValue);
    },
    youtubeRadioType: function () {
      this.initSeriesData();
      let vm = this;
      let youtubeData;
      this.chartOptions.series.map(function (serie, index) {
        if (serie.name == "youtubeAudience") {
          youtubeData = serie;
        }
        if (youtubeData) {
          vm.chart.series[index].remove();
          let seriesDataYoutube = vm.setYoutubeAudienceRatingChart();
          let maxRight = 0;
          for (let item of seriesDataYoutube.data) {
            if (maxRight < Math.abs(item)) {
              maxRight = Math.abs(item);
            }
          }

          vm.$nextTick(() => {
            vm.chart.yAxis[1].update({
              max: maxRight,
            });
            vm.chart.yAxis[1].update();
            vm.chart.addSeries(seriesDataYoutube);
          });
        }
      });
    },
    isStackBar: function () {
      // this.initSeriesData();
    },
    youtubeAudienceRatingReport: {
      handler: function () {
        this.changeStepOrTypeChart(this.step);
      },
      deep: true,
    }
  },
  computed: {
    tick: {
      get: function () {
        return this.chartOptions.xAxis.tickInterval;
      },
      set: function () {
        this.chartOptions.xAxis.tickInterval = 2;
      },
    },
    textInteraction() {
      return {
        rank_2:
          this.project.interaction_btn_1 &&
          Object.keys(this.project.interaction_btn_1)
            ? this.project.interaction_btn_1.text
            : "",
        rank_3:
          this.project.interaction_btn_2 &&
          Object.keys(this.project.interaction_btn_2)
            ? this.project.interaction_btn_2.text
            : "",
        rank_4:
          this.project.interaction_btn_3 &&
          Object.keys(this.project.interaction_btn_3)
            ? this.project.interaction_btn_3.text
            : "",
      };
    },
    isSummaryPercent() {
      return this.$props.chartType.name === "Percentage"
    },
  },
  data() {
    let currentSelected = 0;
    let prevStep = 1;
    let line;
    let currentWidth;
    let vm = this;
    let chartOptions = { ...barChartOption };
    let tooltipEl;
    let lineXaxis;

    chartOptions.plotOptions.series = {
      borderRadiusTopLeft: "8",
      borderRadiusTopRight: "8",
    };
    chartOptions.plotOptions.column.events = {
      click: function (e) {
        vm.legendClickCallback(e);
      },
    };

    chartOptions.xAxis.tickInterval = 2;
    chartOptions.xAxis.grid = {
      columns: getXColumnsByStep(
        this.step,
        (this.part - 1) * this.step * this.stepUnit,
        this.stepUnit
      ),
    };
    chartOptions.xAxis.min = 0.1;
    chartOptions.xAxis.tickInterval = 2;
    chartOptions.xAxis.max = 16.1;
    currentSelected = (this.currentTime / this.step) * 2;
    chartOptions.plotOptions.column.pointWidth = 10;
    chartOptions.xAxis.className = "null";
    chartOptions.xAxis.labels = {
      align: "left",
      useHTML: true,
      formatter: function () {
        return `<div>${
          this.value % 2 === 0 ? (this.value * vm.step) / 2 : ""
        }</div>`;
      },
      style: {
        color: "#999999",
        fontSize: "8px",
        fontWeight: "normal",
        fontFamily: "Avenir Next",
      },
    };
    chartOptions.tooltip = {
      useHTML: true,
      crossHair: [false, true],
      formatter: function () {
        let second = this.key;
        let tooltipEl;

        if (this.series.type == "column") {
          let data = {};
          for (let i = 0; i < NUMBER_BUTTON; i++) {
            data["btn" + (i + 1)] = vm.convertedData[i][second].y;
          }

          let imgEl = getImageTooltipEl(
            vm.imageURL + ((second + 1) / 2 - 1) + ".jpg"
          );
          if (
            vm.chartType.chart == "summary" &&
            vm.$props.dataReport.interaction_tally
          ) {
            for (let i = 0; i < NUMBER_BUTTON; i++) {
              if(vm.isSummaryPercent) {
                data["btn" + (i + 1)] = Number(data["btn" + (i + 1)] / vm.step)
              }
              data["btn" + (i + 1)] =
                addPointZero(String(roundDecimal(data["btn" + (i + 1)], 1))) +
                "% ";
            }
          }
          let reactionTagsEl = getReactionTagTooltipElStep1(
            vm.$props.project,
            data,
            vm.labelChart,
          );
          tooltipEl = getTooltipLineChartStep1(imgEl, reactionTagsEl);
          return tooltipEl;
        } else {
          return "";
        }
      },
      borderWidth: 0,
      backgroundColor: "transparent",
      shadow: false,
      padding: 0,
      followPointer: false,
      shared: false,
      distance: 2,
      className: "tootlip-line",
      convertedData: [],
    };
    return {
      chart: null,
      tooltipEl,
      currentSelected,
      prevStep,
      line,
      currentWidth,
      lineXaxis,
      changeTime: null,
      chartOptions: {
        chart: {
          type: "column",
          height: "270px",
          animation: false,
          events: {
            load: function (e) {
              this.chart = e.chart;
              vm.load(e);
            },
            redraw: function (e) {
              vm.redraw(e);
            },
          },
        },
        ...chartOptions,
      },
      pointline: {},
      isShowBorder: true,
      isStackBar: this.$store.getters.getStackBarValue,
    };
  },
  methods: {
    load(e) {
      let chart = e.target;
      this.chart = chart;
    },
    redraw(e) {
      let chart = e.target;
      this.chart = chart;
    },
    handleChangeStep(step) {
      this.step = step;
    },
    initSeriesData() {
      let series = [];
      let step = this.step ? this.step : 1;
      let vm = this;
      if (vm.seriesData.length) {
        vm.isShowBorder = true;
        vm.convertedData = [];
        vm.seriesData.forEach((serieData, index) => {
          let data = formatDataChartWithStepBarChartStep1(serieData.data, step);
          vm.convertedData.push(JSON.parse(JSON.stringify(data)));
          if (!vm.labelChart["btn" + (index + 1)].visible) {
            data = data.map(function (item) {
              item['y'] = null
              return item
            })
          }

          if(vm.isSummaryPercent) {
            data = data.map(function (item) {
              if(item.y) {
                item.y = Number(item.y) / step
              }
              return item
            })
          }

          let serie = {
            type: "column",
            name: serieData.name,
            data: data,
            color: serieData.color,
            animation: false,
            border: serieData.border,
            yAxis: 0,
            states: {
              hover: {
                opacity: 1,
                lineWidth: 2,
              },
              inactive: {
                opacity: 1,
              },
            },
          };
          series.push(serie);
        });
      }
      let seriesDataYoutube = vm.setYoutubeAudienceRatingChart();
      if (
        seriesDataYoutube &&
        vm.$props.chartType &&
        vm.$props.chartType.name == "Interaction"
      ) {
        vm.chartOptions.yAxis[1].visible = true;
        series.push(seriesDataYoutube);
      } else if (!seriesDataYoutube) {
        vm.chartOptions.yAxis[1].visible = false;
      }
      this.currentSelected = (this.currentTime / this.step) * 2;
      vm.chartOptions.series = JSON.parse(JSON.stringify(series));
      vm.initPointLineData();
      vm.setYmax();
      vm.$nextTick(() => {
        vm.chart.redraw();
      });
    },

    initPointLineData() {
      let arrayData = [];
      if (
        !this.is25percent &&
        !(this.$props.chartType && this.$props.chartType.interaction_tally)
      ) {
        for (let i = 1; i <= NUMBER_BUTTON; i++) {
          if (
            this.labelChart["btn" + i].visible &&
            this.seriesData[i - 1] &&
            this.seriesData[i - 1].data
          ) {
            arrayData.push(this.chartOptions.series[i - 1].data);
          }
        }
        let data_sum = this.formatPointLine(arrayData);
        if (Object.keys(data_sum).length) {
          let pointLine = [];
          let pointLineMax = {
            name: "max",
            value: data_sum["max"],
            color: "#AB7249",
            zIndex: data_sum["avg"] > data_sum["max"] ? 100 : 150,
            dashStyle: "dash",
            label: {
              className: "span-max-plot-line",
              text: "最大反応値",
              align: "right",
              useHTML: true,
              formatter: function () {
                return '<div class="max-plot-line">最大反応値</div>';
              },
            },
          };
          pointLine.push(pointLineMax);
          let pointLineAvg = {
            name: "avg",
            value: data_sum["avg"],
            color: "#49AB94",
            zIndex: data_sum["max"] > data_sum["avg"] ? 100 : 150,
            dashStyle: "dash",
            label: {
              className: "span-avg-plot-line",
              useHTML: true,
              formatter: function () {
                return '<div class="avg-plot-line">平均反応値</div>';
              },
              text: "平均反応値",
              align: "right",
            },
          };
          pointLine.push(pointLineAvg);
          this.chartOptions.yAxis[0].plotLines = pointLine;
          if (
            this.$props.chartType &&
            this.$props.chartType.chart == "summary"
          ) {
            EventBus.$emit(
              "setMaAvgInteraction",
              data_sum["max"],
              data_sum["avg"]
            );
          }
        } else {
          if (
            this.$props.chartType &&
            this.$props.chartType.chart == "summary"
          ) {
            EventBus.$emit("setMaAvgInteraction", "-", "-");
          }
        }
      } else {
        this.chartOptions.yAxis[0].plotLines = [];
      }
      this.chartOptions.xAxis.plotLines = [
        {
          className: "line-show-tooltip",
          color: "#999999",
          width: 1,
          value: this.currentSelected,
          zIndex: 4,
        },
      ];
    },
    formatPointLine(arrayData) {
      let sum_data = [];
      arrayData.forEach((series) => {
        series.forEach((value) => {
          sum_data.push(value['y']);
        });
      });
      let interaction_max = interactionMax(sum_data);
      let interaction_avg = interactionAvg(sum_data);
      let pointLine = {};
      if (!isNaN(interaction_max)) {
        pointLine["max"] = interaction_max;
      }
      if (!isNaN(interaction_avg)) {
        pointLine["avg"] = interaction_avg;
      }
      this.pointline = pointLine;
      return pointLine;
    },
    setYmax() {
      this.chartOptions.yAxis[0].allowDecimals = false;
      this.chartOptions.yAxis[0].minRange = null;
      this.chartOptions.yAxis[0].min = 0;
      this.chartOptions.yAxis[1].max = 100;
      if (!(this.$props.chartType && this.$props.chartType.interaction_tally))
      {
        this.chartOptions.yAxis[0].endOnTick = false;
        this.chartOptions.yAxis[0].max = this.pointline.max;
      } else {
        this.chartOptions.yAxis[0].minRange = 100;
        this.chartOptions.yAxis[0].max = null;
        this.chartOptions.yAxis[0].endOnTick = false;
      }
    },
    updateLineChart() {
      let chart = this.chart;
      chart.xAxis[0].options.plotLines[0].value = this.currentSelected;
      chart.xAxis[0].update();
    },
    legendClickCallback(e) {
      this.changeTime = e.point.category / 2 * this.step;
    },
    setYoutubeAudienceRatingChart() {
      let youtubeData = JSON.parse(JSON.stringify(this.$props.youtubeAudienceRatingReport));
      if (youtubeData) {
        let youtubeRatingData = this.formatYoutubeLine(youtubeData)
        this.isShowBorder = true;
        return {
          type: "line",
          pointRadius: 0,
          stacking: "normal",
          name: "youtubeAudience",
          data: youtubeRatingData,
          color: "#49AB94",
          animation: false,
          isSerieRightBorderShow: false,
          visible: true,
          yAxis: 1,
          states: {
            hover: {
              opacity: 1,
              lineWidth: 2,
            },
            inactive: {
              opacity: 1,
            },
          },
          marker: {
            enabled: false,
            states: {
              hover: {
                enabled: false,
              },
            },
          },
        };
      } else {
        return null;
      }
    },
    formatYoutubeLine(data) {
      let vm = this;
      return data.map(function (item) {
        if(vm.step === 1) {
          return {
            x: item.second * vm.step * 2,
            y: item.rate
          }
        } else if (vm.step === 2) {
          return {
            x: item.second * vm.step / 2,
            y: item.rate
          }
        } else if (vm.step === 4) {
          return {
            x: item.second * vm.step / 8,
            y: item.rate
          }
        } else if (vm.step === 8) {
          return {
            x: item.second * vm.step / 32,
            y: item.rate
          }
        }
      })
    },
    changeStepOrTypeChart(newValue) {
      this.chartOptions.xAxis.tickInterval = 2;
      this.chartOptions.xAxis.grid = {
        columns: getXColumnsByStep(newValue, 0, this.stepUnit),
      };
      this.chartOptions.xAxis.min = 0.1;
      this.chartOptions.xAxis.max = 16.1;
      this.tick = newValue;
      this.initSeriesData();
    },
  },
  mounted() {
    this.initSeriesData();
    this.changeStepOrTypeChart(this.step);
    this.chart = this.$refs.barChart.chart;
    this.chart.redraw();
  },
  created() {
    !this.isStackBarParent ? (this.isStackBar = this.isStackBarParent) : true;
    this.initSeriesData();
  },
};
</script>
<style lang="scss">
@import "@/styles/variable";
.x-column-25 {
  // top: 18px!important;
  .label-x {
    display: flex;
    justify-content: space-between;
    width: 136px;
    color: $middle-black;
    @include font(s);
  }
}
.span-max-plot-line {
  z-index: 150;
  .max-plot-line {
    background-color: $orange;
    color: $white;
    border-radius: 2px;
    height: 12px;
    padding: 0px 1px 0px 1px;
    @include font(xs);
    line-height: 12px;
    margin-top: 8px;
  }
}
.span-avg-plot-line {
  z-index: 100;
  .avg-plot-line {
    background-color: $green;
    color: $white;
    border-radius: 2px;
    height: 12px;
    padding: 1px 1px 0px 1px;
    @include font(xs);
    line-height: 12px;
    margin-top: 8px;
  }
}
.highcharts-yaxis-labels {
  font-family: "Avenir Next";
}
</style>
