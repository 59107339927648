<template>
    <div class="row-3-col-2 chart-area">
      <step-option
          :options="stepOptions"
          :selected="step"
          :eventName="changeStepOption"
        ></step-option>
        <div class="title-y-axis" :class="{'screening-option-mode': questionIdSelected}">
          {{ questionIdSelected && isTotalChart ? '比率' : 'Interaction' }}
        </div>
        <div class="title-x-axis">Time</div>
        <i class="icon icon-prev" v-if="prevPartEnabled" @click="prevPart"></i>
        <i class="icon icon-next" v-if="(nextPartEnabled && videoAttributeId) || (nextPartEnabled && questionIdSelected && !isTotalChart)" @click="nextPart"></i>
        <i class="icon icon-next" v-else-if="nextPartAvailable && questionIdSelected && isTotalChart" @click="nextPart"></i>
        <div v-if="interactionButtonColor.rank_2 && interactionButtonColor.rank_3">
          <line-chart
            @setChart="getChart"
            v-if="isTotalChart"
            :part="part"
            :dataReport="reportSegments[0]"
            :step="step"
            :changeStepChart="changeStepOption"
            :seriesData="seriesData"
            :allSeriesData="allSeriesData"
            :selectedId="selectedSeriesId"
            :imageURL="image_url"
            :key="key"
            :column="column"
            :maxColumn="maxColumn"
            :lineChart="lineChart"
            :interactionType="videoDetail.interaction_type"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :project="videoDetail"
            :reportScreening="reportScreening"
            :answerValueIdSelected="answerValueIdSelected"
            :selectedAnswerId="selectedAnswerId"
            :questionIdSelected="questionIdSelected"
            :answerValueSelected="answerValueSelected"
            :dynamicButton="true"
            :numberElmTooltip="LABEL_TYPE_REACTION.length"
            :isTotalChart="isTotalChart"
            :labelChart="label"
          ></line-chart>
          <line-chart
            @setChart="getChart"
            v-else-if="!questionIdSelected"
            :part="part"
            :dataReport="reportSegments[0]"
            :step="step"
            :seriesData="getSeriesDataChartEachInteraction(attributeValueIdSelected)"
            :allSeriesData="getSeriesDataChartEachInteraction(allAttributeValueIdSelected)"
            :selectedId="selectedSeriesId"
            :interactionType="videoDetail.interaction_type"
            :imageURL="image_url"
            :column="column"
            :maxColumn="maxColumn"
            :numberColumn="column"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :project="videoDetail"
            :reportScreening="reportScreening"
            :answerValueIdSelected="answerValueIdSelected"
            :selectedAnswerId="selectedAnswerId"
            :questionIdSelected="questionIdSelected"
            :answerValueSelected="answerValueSelected"
            :dynamicButton="true"
            :numberElmTooltip="LABEL_TYPE_REACTION.length"
            :lineChart="lineChart"
            :isShared="true"
            :isTotalChart="isTotalChart"
            :labelChart="label"
          ></line-chart>
          <line-chart
            @setChart="getChart"
            v-else
            :part="part"
            :dataReport="reportSegments[0]"
            :step="step"
            :seriesData="getSeriesDataChartScreening(answerValueIdSelected)"
            :allSeriesData="getSeriesDataChartScreening(answerValueSelected, false)"
            :selectedId="selectedAnswerId"
            :interactionType="videoDetail.interaction_type"
            :imageURL="image_url"
            :column="column"
            :maxColumn="maxColumn"
            :numberColumn="column"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :project="videoDetail"
            :reportScreening="reportScreening"
            :answerValueIdSelected="answerValueIdSelected"
            :selectedAnswerId="selectedAnswerId"
            :questionIdSelected="questionIdSelected"
            :answerValueSelected="answerValueSelected"
            :dynamicButton="true"
            :numberElmTooltip="LABEL_TYPE_REACTION.length"
            :lineChart="lineChart"
            :isShared="true"
            :isTotalChart="isTotalChart"
            :labelChart="label"
          ></line-chart>
        </div>
        <div v-else>
          <line-chart
            @setChart="getChart"
            v-if="isTotalChart"
            :part="part"
            :dataReport="reportSegments[0]"
            :step="step"
            :changeStepChart="changeStepOption"
            :seriesData="seriesData"
            :allSeriesData="allSeriesData"
            :selectedId="selectedSeriesId"
            :imageURL="image_url"
            :key="key"
            :column="column"
            :maxColumn="maxColumn"
            :lineChart="lineChart"
            :interactionType="videoDetail.interaction_type"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :project="videoDetail"
            :reportScreening="reportScreening"
            :answerValueIdSelected="answerValueIdSelected"
            :selectedAnswerId="selectedAnswerId"
            :questionIdSelected="questionIdSelected"
            :answerValueSelected="answerValueSelected"
            :dynamicButton="true"
            :numberElmTooltip="LABEL_TYPE_REACTION.length"
            :isTotalChart="isTotalChart"
            :labelChart="label"
          ></line-chart>
          <line-chart2
            @setChart="getChart"
            v-else
            :part="part"
            :dataReport="reportSegments[0]"
            :step="step"
            :changeStepChart="changeStepOption"
            :seriesData="seriesData"
            :allSeriesData="allSeriesData"
            :selectedId="selectedSeriesId"
            :imageURL="image_url"
            :key="key"
            :column="column"
            :maxColumn="maxColumn"
            :lineChart="lineChart"
            :interactionType="videoDetail.interaction_type"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
            :project="videoDetail"
            :isArea="true"
            :reportScreening="reportScreening"
            :answerValueIdSelected="answerValueIdSelected"
            :selectedAnswerId="selectedAnswerId"
            :questionIdSelected="questionIdSelected"
            :answerValueSelected="answerValueSelected"
            :dynamicButton="true"
            :numberElmTooltip="LABEL_TYPE_REACTION.length"
            :isTotalChart="isTotalChart"
            :labelChart="label"
          ></line-chart2>
        </div>
    </div>
</template>
<script>
import StepOption from "@/components/TopDetail/StepOption.vue";
import { stepOptionTopDetailPage } from "@/commons/charts";
import LineChart from "@/components/Charts/Step1/Line.vue";
import EventBus from "@/commons/event_bus";
import { LABEL_TYPE_REACTION } from "@/commons/constants.js"

export default {
  name: "SegmentChart",
  components: {
    StepOption,
    LineChart,
    'line-chart2' : LineChart
  },

  props: [
    'reportSegments',
    'attributeValueIdSelected',
    'allAttributeValueIdSelected',
    'selectedSeriesId',
    'seriesData',
    'allSeriesData',
    'videoImage',
    'videoDetail',
    'isTotalChart',
    'reportScreening',
    'selectedAnswerId',
    'answerValueIdSelected',
    'videoAttributeId',
    'questionIdSelected',
    'answerValueSelected'
],
  data() {
    return {
      chart: null,
      stepOptions: stepOptionTopDetailPage,
      changeStepOption: "changeStepSegmentChart",
      step: 1,
      part: 1,
      key: 1,
      column: 15,
      maxColumn: 15.5,
      lineChart: "chartAttribute",
      nextPartAvailable: false,
      LABEL_TYPE_REACTION,
      label: {
        btn1: {},
        btn2: {},
        btn3: {},
      },
    };
  },
  watch: {
    isTotalChart: function() {
      this.step = 1;
      this.part = 1;
      this.nextPartAvailable = false
      this.$nextTick(() => {
        if (this.chart) {
          this.chart.reflow();
        }
      });
    },
    questionIdSelected() {
      this.part = 1
    }
  },
  methods: {
    // changeTypeChart(isTotalInteraction) {
    //   this.typeChart = isTotalInteraction;
    // },
    getChart(chart) {
      this.chart = chart;
    },
    handleChangeStepChart(step) {
      this.step = step;
      this.part = 1;
    },
    nextPart() {
      this.part += 1;
    },
    prevPart() {
      this.part -= 1;
    },
    addClassActive(data) {
      this.$emit("addClassActive", data);
    },
    removeClassActive(data) {
      this.$emit("removeClassActive", data);
    },
    getSeriesDataChartEachInteraction(attributeValueIdSelected) {
        let series = [];
        let reportData = this.reportSegments[0]?.data
        attributeValueIdSelected.forEach(id => {
            let attrValueid = id.split('-')[1];
            if (reportData) {
              let arrData = [];
              for (let i = 1; i <= LABEL_TYPE_REACTION.length; i++){
                arrData.push([])
              }
              reportData.forEach(data => {
                  let attrData = {}
                  attrData = data.values.find(e => attrValueid == e.attribute_value_id )
                  if (attrData) {
                    for (let i = 1; i <= LABEL_TYPE_REACTION.length; i++){
                      arrData[i - 1].push(Math.abs(attrData[`btn${i}`]));
                    }
                  } else {
                    for (let i = 1; i <= LABEL_TYPE_REACTION.length; i++){
                      arrData[i - 1].push(0);
                    }
                  }
                  
              });
              arrData.forEach((arr, index) => {
                let serie = {
                  id: 'attrValue-' + attrValueid + index,
                  mainId: 'attrValue-' + attrValueid,
                  data: arr,
                  colorLine: this.videoDetail[`interaction_btn_${index + 1}`]?.color
                }
                series.push(serie)
              });
              
            }
            
        })
        return series;
    },
    getSeriesDataChartScreening(answerValueIdSelected, isListId = true) {
      if(!isListId) {
        answerValueIdSelected = answerValueIdSelected.map(item => Object.keys(item)[0])
      }
      let allSeries = [];
      let reportData = this.reportScreening?.data
      answerValueIdSelected.forEach(id => {
        if (reportData) {
          let arrData = [];
          for (let i = 1; i <= LABEL_TYPE_REACTION.length; i++){
            arrData.push([])
          }

          arrData.forEach((arr, index) => {
            reportData.forEach(data => {
              let dataByAnswer = data.values.filter(item => item.screening_answer_id == id)[0]
              if(index === 0) {
                arrData[index].push(dataByAnswer?.interest_count ? dataByAnswer?.interest_count : 0)
              } else if(index === 1) {
                arrData[index].push(dataByAnswer?.understand_count ? dataByAnswer?.understand_count : 0)
              } else {
                arrData[index].push(dataByAnswer?.purchase_count ? dataByAnswer?.purchase_count : 0)
              }
            })
          });

          arrData.forEach((arr, index) => {
            let series = {
              id: id + index,
              mainId: id,
              data: arr,
              colorLine: this.videoDetail[`interaction_btn_${index + 1}`]?.color
            }
            allSeries.push(series)
          });
        }
      })

      return allSeries;
    },
    nextPartActive(val) {
      this.nextPartAvailable = val
    },
    initLabelData() {
      let videoDetail = JSON.parse(JSON.stringify(this.videoDetail))
      let listLabel = [
        videoDetail?.interaction_btn_1,
        videoDetail?.interaction_btn_2,
        videoDetail?.interaction_btn_3,
      ]

      for (const key in listLabel) {
        this.label["btn" + (Number(key) + 1)] = listLabel[key];
      }
    }
  },
  computed: {
    nextPartEnabled: function () {
      let seriesLength = this.seriesData.map((e) => e.data.length);
      let max = Math.max(...seriesLength) - 1;
      return max > this.part * this.step * 15;
    },
    prevPartEnabled: function () {
      return this.part > 1;
    },
    image_url:  function () {
      return (this.$props.videoImage) ? this.$props.videoImage.image_url : null
    },
    interactionButtonColor: function(){
      let color_rank_2 = this.videoDetail.interaction_btn_1 && Object.keys(this.videoDetail.interaction_btn_1).length ? this.videoDetail.interaction_btn_1.color : '';
      let color_rank_3 = this.videoDetail.interaction_btn_2 && Object.keys(this.videoDetail.interaction_btn_2).length ? this.videoDetail.interaction_btn_2.color : '';
      let color_rank_4 = this.videoDetail.interaction_btn_3 && Object.keys(this.videoDetail.interaction_btn_3).length ? this.videoDetail.interaction_btn_3.color : '';
      return {
        rank_2 : color_rank_2,
        rank_3 : color_rank_3,
        rank_4 : color_rank_4,
      }
    }
  },
  mounted() {
    this.initLabelData();
  },
  created() {
    EventBus.$on("changeStepSegmentChart", this.handleChangeStepChart);
    EventBus.$on("nextPartActive", this.nextPartActive);
  },
  beforeDestroy() {
    EventBus.$off("changeStepSegmentChart", this.handleChangeStepChart);
    EventBus.$off("nextPartActive", this.nextPartActive);
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/variable';
.chart-area {
    position: relative;
    @include font(xs);
    color: $middle-black;
    .title-y-axis {
        position: absolute;
        top: 0;
        left: 12px;
        font-weight: bold;
        z-index: 1;
    }
    .screening-option-mode {
      left: 28px;
    }
    .title-x-axis {
        position: absolute;
        right: 4px;
        bottom: 20px;
        font-weight: bold;
        z-index: 3;
    }
    #segment-chart-container {
        margin-top: 12px;
        margin-right: 12px;
    }
    .icon-next {
        position: absolute;
        right: 40px;
        bottom: -40px;
        z-index: 0;
        cursor: pointer;
        &.d-none {
            display: none;
        }
    }
    .icon-prev {
        position: absolute;
        z-index: 1;
        bottom: -40px;
        left: 42px;
        cursor: pointer;
        &.d-none {
            display: none;
        }
    }
}
</style>