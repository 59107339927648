<template>
  <div>
    <div
      class="capture-videos has-video-selected mt-5"
      style="padding-top: 38px"
      v-if="listImage != null && listImage.length"
    >
      <div class="list-mark"></div>
      <div class="type-reaction">
        <div
          v-for="(type, index) in listInteraction"
          :key="index"
          class="type"
          :class="{'mr-2' : index !== (listInteraction.length - 1)}"
          :style="{color : type ? type.color : ''}"
        >
          <span class="dot-color" :style="{backgroundColor : type ? type.color : ''}"></span>{{ type ? type.text : '' }}
        </div>
      </div>
      <div class="list-video-captures">
        <div class="video-capture step1">
          <div
            class="images mt-2"
            id="video-image"
            v-if="listImage != null && listImage.length"
            ref="imageVideo"
          >
            <div
              class="image-second"
              v-for="(item, index) in listImage"
              :key="item.second"
            >
              <div v-if="Object.keys(item).length">
                <div
                  class="second-plus"
                  ref="secondLine"
                  :data-second="item.second"
                  :style="{ width: getWidth(item.second, index) }"
                >
                  <div class="second-add-label" v-for="(second,idx) in noLineSeconds" :key="`second-${idx}`" >
                    <div class="second-plus--add-mark" v-if="second == item.second" @click="addTabularLine(second)">
                        <i class="icon icon-second-add"></i>
                    </div>
                  </div>
                  <div v-for="(tabular,index) in listTabular" :key="tabular.tabular_line_id" :style="{width : '100%',position:'absolute'}">
                      <div class="second-plus--info-mark" v-if="tabular.tabular.second.start == item.second || tabular.tabular.second.start <= 13 * (page-1) && tabular.tabular.second.end >= 13 * (page-1) && item.second == 13 * (page-1)">
                        <div class="line-text">
                          <div class="edit-line-second">
                            <div class="line-second-inner">
                              <div class="edit-second edit-first-second">
                                <input class="input-first-line" type="number" :active="tabular.tabular.second.start" :value="tabular.tabular.second.start" @change="editFirstLine(index, tabular)" :class="[tabular.tabular.second.start == 13*(page-1) ? 'input-short' : '']">
                                <p :class="tabular.tabular.second.start == 13*(page-1) ? 'second-short' : ''">s</p>
                              </div>
                              <div class="between">
                                <p>~</p>
                              </div>
                              <div class="edit-second edit-second-second">
                                <input class="input-second-line" type="number" :active="tabular.tabular.second.end" :value="tabular.tabular.second.end" @change="editSecondLine(index, tabular)" :class="[tabular.tabular.second.end == 13*(page-1) ? 'input-short' : '', tabular.tabular.second.end < 10 ? 'one-digit' : tabular.tabular.second.end > 10 && tabular.tabular.second.end < 100 ? 'two-digit' : '']">
                                <p :class="[tabular.tabular.second.end == 13*(page-1) ? 'second-short' : '',tabular.tabular.second.end < 10 ? 'one-digit-second' : tabular.tabular.second.end > 10 && tabular.tabular.second.end < 100 ? 'two-digit-second' : '']">s</p>
                              </div>
                              </div>
                            </div>
                              <span>{{tabular.tabular.cell_notes.content}}</span>
                              <div class="trash"><i class="icon icon-trash" @click="deleteTabularLine(tabular)"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tag-seconds" style="margin-top: 4px">
                  {{ item.second }}s
                </div>
                <div class="capture" @click="choseTimeVideo(item.second)">
                  <img class="capture-second" :src="item.image_url" /><i
                    class="icon icon-arrow"
                    :class="index + start == duration ? 'hide' : ''"
                  ></i>
                </div>
                <div
                  class="tag-number"
                  v-if="
                    Object.keys(item.rank_2).length &&
                    interactionColor &&
                    interactionColor.btn1
                  "
                  :style="{backgroundColor: interactionColor.btn1}"
                >
                  <div class="ranking">
                    <span
                        :style="{color: interactionColor.btn1}"
                    >{{ item.rank_2.rank }}</span>
                  </div>
                  <div class="">{{ item.rank_2.interaction_count }}</div>
                </div>
                <div
                  class="tag-number"
                  v-if="
                    Object.keys(item.rank_3).length &&
                    interactionColor &&
                    interactionColor.btn2
                  "
                  :style="{backgroundColor: interactionColor.btn2}"
                >
                  <div class="ranking">
                    <span
                        :style="{color: interactionColor.btn2}"
                    >{{ item.rank_3.rank }}</span>
                  </div>
                  <div class="">{{ item.rank_3.interaction_count }}</div>
                </div>
                <div
                  class="tag-number"
                  v-if="
                    Object.keys(item.rank_4).length &&
                    interactionColor &&
                    interactionColor.btn3
                  "
                  :style="{backgroundColor: interactionColor.btn3}"
                >
                  <div class="ranking">
                    <span
                        :style="{color: interactionColor.btn3}"
                    >{{ item.rank_4.rank }}</span>
                  </div>
                  <div class="">{{ item.rank_4.interaction_count }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-next-prev" v-if="listImage != null && listImage.length">
        <i
          class="icon icon-prev"
          @click="changeListImagePrev"
          v-if="isIconPrev"
          ref="prevButton"
        ></i>
        <i
          class="icon icon-next"
          @click="changeListImageNext"
          v-if="isIconNext"
          ref="nextButton"
        ></i>
      </div>
    </div>
    <div class="labels-list mt-11" v-if="videoImage && videoImage.duration > 0">
      <div class="table-labels table-wrapper">
        <div class="loader" v-if="isLoading"></div>
        <table id="tabledata" class="table table-scroll step1">
          <colgroup>
            <col class="table-col" />
            <col class="table-col" />
            <col class="table-col" />
            <col class="table-col" />
            <col class="table-col" />
          </colgroup>
          <tr>
            <th style="width: 20%">シーン</th>
            <th
              v-if="
                interactionText &&
                interactionText.btn1 &&
                interactionText.btn1.length
              "
            >
              {{ interactionText.btn1 }}
            </th>
            <th
              v-if="
                interactionText &&
                interactionText.btn2 &&
                interactionText.btn2.length
              "
            >
              {{ interactionText.btn2 }}
            </th>
            <th
              v-if="
                interactionText &&
                interactionText.btn3 &&
                interactionText.btn3.length
              "
            >
              {{ interactionText.btn3 }}
            </th>
            <th>Interaction</th>
            <th>自由記入欄 <ToolTip :text="$t('texts.tooltip.comment')"></ToolTip></th>
          </tr>
          <tr
            v-for="(item, index) in listTabular"
            :key="'line-' + item.tabular_line_id"
          >
            <td
              style="width: 20%"
              :data-index="index"
              data-row="cell_notes"
              :data-cell="0"
              :data-id="item.tabular_line_id"
              :class="
                item.tabular.cell_notes.updated_user_name ? 'col-edit-text' : ''
              "
              class="col-edit-cell-notes col-note step1 first"
            >
              <span
                class="tooltiptext"
                v-if="item.tabular.cell_notes.updated_user_name"
                >最終編集者：{{
                  item.tabular.cell_notes.updated_user_name
                }}</span
              >
              <textarea
                @input="setHeight($event)"
                rows="1"
                class="table-textarea input-content input-cell-note mr-5"
                v-model="item.tabular.cell_notes.content"
                @change="editCellNote(index)"
              ></textarea>
              <img
                class="img-cell-note"
                :src="getImageSecond(item.tabular.second.start)"
                alt=""
              />
            </td>
            <td
              :style="[
                getMax(index, maxBtn1Interaction) && average(item.tabular.btn1) > 0
                  ? { color: listInteraction[0].color, fontWeight: 'bold' }
                  : { color: defaultColor },
              ]"
            >
              <div
                :style="{
                  'background-color':
                    average(item.tabular.btn1) > 0
                      ? listInteraction[0].backgroundColor
                      : '#fff',
                  width: getPercentWidth(0, item),
                }"
                class="background"
              >
                <span> {{ average(item.tabular.btn1) }}</span>
              </div>
            </td>
            <td
              :style="[
                getMax(index, maxBtn2Interaction) && average(item.tabular.btn2) > 0
                  ? { color: listInteraction[1].color, fontWeight: 'bold' }
                  : { color: defaultColor },
              ]"
            >
              <div
                :style="{
                  'background-color':
                    average(item.tabular.btn2) > 0
                      ? listInteraction[1].backgroundColor
                      : '#fff',
                  width: getPercentWidth(1, item),
                }"
                class="background"
              >
                <span>{{ average(item.tabular.btn2) }}</span>
              </div>
            </td>
            <td
              :style="[
                getMax(index, maxBtn3Interaction) && average(item.tabular.btn3) > 0
                  ? { color: listInteraction[2].color, fontWeight: 'bold' }
                  : { color: defaultColor },
              ]"
            >
              <div
                :style="{
                  'background-color':
                    average(item.tabular.btn3) > 0
                      ? listInteraction[2].backgroundColor
                      : '#fff',
                  width: getPercentWidth(2, item),
                }"
                class="background"
              >
                <span>{{ average(item.tabular.btn3) }}</span>
              </div>
            </td>
            <td
              :style="[
                getMax(index, maxAvgInteraction)
                && totalAvg(
                    average(item.tabular.btn1),
                    average(item.tabular.btn2),
                    average(item.tabular.btn3)
                  ) > 0
                  ? { color: avgColor, fontWeight: 'bold' }
                  : { color: defaultColor },
              ]"
            >
              <div
                :style="{
                  'background-color':
                    totalAvg(
                      average(item.tabular.btn1),
                      average(item.tabular.btn2),
                      average(item.tabular.btn3)
                    ) > 0
                      ? '#EDF7F4'
                      : '#fff',
                  width: getPercentWidthTotal(item),
                }"
                class="background"
              >
                <span>{{
                  totalAvg(
                    average(item.tabular.btn1),
                    average(item.tabular.btn2),
                    average(item.tabular.btn3)
                  )
                }}</span>
              </div>
            </td>

            <td
              :data-index="index"
              data-row="free_notes"
              :data-cell="1"
              :data-id="item.tabular_line_id"
              :class="
                item.tabular.free_notes.updated_user_name ? 'col-edit-text' : ''
              "
              class="col-edit-free-notes col-note"
            >
              <span
                class="tooltiptext"
                v-if="item.tabular.free_notes.updated_user_name"
                >最終編集者：{{
                  item.tabular.free_notes.updated_user_name
                }}</span
              >
              <textarea
                rows="1"
                @input="setHeight($event)"
                class="table-textarea input-content input-free-note"
                type="text"
                v-model="item.tabular.free_notes.content"
                @change="editFreeNote(index)"
                @keydown="onKeydownEditFreeNote(index)"
              ></textarea>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { numberWithCommas } from "@/commons/helpers";
import EventBus from "@/commons/event_bus";
import { cellFlg, LABEL_TYPE_REACTION } from "@/commons/constants";
import ToolTip from "@/components/Commons/ToolTip";
const jQuery = require("jquery");
export default {
  name: "VideoCapture",
  components: { ToolTip },
  props: ["videoDetail", "videoImage", "reportVideo", "tabulars"],
  data() {

    let videoDetail = JSON.parse(JSON.stringify(this.videoDetail))
    let listInteraction =  [
      videoDetail?.interaction_btn_1,
      videoDetail?.interaction_btn_2,
      videoDetail?.interaction_btn_3,
    ]

    return {
      listImage: null,
      start: 0,
      end: 0,
      duration: 0,
      isIconNext: true,
      isIconPrev: false,
      listTabular: [],
      secondsLength: 0,
      startSecond: 0,
      endSecond: 0,
      cellFlg: 0,
      contents: "",
      status: 1,
      currentUser: this.$store.getters.currentUser,
      lineId: null,
      listCellFlg: cellFlg,
      videoId: null,
      avgColor: "#49AB94",
      defaultColor: "#333333",
      page: 1,
      isLoading: false,
      LABEL_TYPE_REACTION,
      NUMBER_BUTTON: LABEL_TYPE_REACTION.length,
      listInteraction,
      isLineSeconds: [],
      noLineSeconds: [],
      caputureWidth: 0,
    };
  },
  watch: {
    videoImage: function () {
      this.setStartEndImage();
      this.getNoLineId(this.tabulars)
      this.getCaputureWidth(500)
    },
    tabulars: function (data) {
      this.getlistTabular(data);
      this.getNoLineId(data);
    },
    videoDetail: function (data) {
      this.videoId = data.id;
    },
    listTabular: function () {
      this.$nextTick(() => {
        let textArea = jQuery(".table-textarea");
        textArea.each(function () {
          if (this.scrollHeight - jQuery(this).height() > 4) {
            let height = this.scrollHeight;
            this.style.height = height + "px";
          }
        });
      });
    },
    page: function(){
      this.getCaputureWidth(0);
    }
  },

  mounted() {
    this.setStartEndImage();
    this.videoId = this.videoDetail.id
    this.getlistTabular(this.tabulars)
    window.addEventListener('resize', function(){
      this.getCaputureWidth(0)
    }.bind(this))
  },

  updated() {
    this.setHeightOfInputFreeNote();
    jQuery("#tabledata textarea").each(function () {
      jQuery(this).css("height", "fit-content");
    });
    jQuery("#tabledata textarea").each(function () {
      if (jQuery(this).prop("scrollHeight") - jQuery(this).height() > 4) {
        jQuery(this).css("height", "auto");
        let height = jQuery(this).prop("scrollHeight");
        if(jQuery(this).height() > 40) {
          jQuery(this).css("height", height - 2 + "px");
        } else {
          jQuery(this).css("height", height + "px");
        }
      }
    });
    this.getHeightMax();
  },

  methods: {
    getImageSecond(index = 0) {
      const urlArr = this.listImage[0]?.image_url.split("/");
      urlArr.pop();
      const url = urlArr.join("/");
      return url + `/${index}.jpg`;
    },
    getPercentWidthTotal(item) {
      let arr = [];
      this.listTabular.forEach((element) => {
        arr.push(Number(this.totalAvg(
          this.average(element.tabular.btn1),
          this.average(element.tabular.btn2),
          this.average(element.tabular.btn3)
        )));
      });

      let max = Math.max(...arr).toFixed(2)

      return (
          this.totalAvg(this.average(item.tabular.btn1), this.average(item.tabular.btn2), this.average(item.tabular.btn3)) / max * 100 + "%"
      );
    },
    getPercentWidth(number, item) {
      return this.average(item.tabular[`btn${number + 1}`]) / this[`maxValueBtn${number + 1}Interaction`] * 100 + '%'
    },
    getHeightMax() {
      let children = jQuery("#tabledata tr");
      children.each(function () {
        let heightMax = 40;
        jQuery(this)
          .children()
          .each(function () {
            jQuery(this).css("min-height", "fit-content");
            if (heightMax < jQuery(this).height())
              heightMax = jQuery(this).height();
          });
        jQuery(this)
          .children()
          .each(function () {
            jQuery(this).css("min-height", heightMax + "px");
          });
      });
    },
    onKeydownEditFreeNote(index) {
      let inputFreeNote = [...jQuery("#tabledata .input-free-note")][index];
      setTimeout(function () {
        inputFreeNote.style.cssText = "height:auto; padding:0";
        inputFreeNote.style.cssText =
          "height:" + inputFreeNote.scrollHeight + "px";
      }, 0);
    },

    setHeightOfInputFreeNote() {
      let inputFreeNoteList = [...jQuery("#tabledata .input-free-note")];
      inputFreeNoteList.forEach((inputFreeNote) => {
        if (inputFreeNote.scrollHeight - jQuery(inputFreeNote).height() > 4) {
          let height = inputFreeNote.scrollHeight;
          inputFreeNote.style.height = height + "px";
        }
      });
    },

    getMax(index, maxInteraction) {
      let idx = maxInteraction.filter((item) => item == index);
      return idx.length > 0;
    },
    setHeight(e) {
      jQuery("#tabledata textarea").each(function () {
        jQuery(this).css("height", "fit-content");
      });
      this.getHeightMax();
      if (e.target.scrollHeight - jQuery(e.target).height() > 4) {
        e.target.style.height = "auto";
        let height = e.target.scrollHeight;
        e.target.style.height = height + "px";
      }
    },
    getWidth(second, index) {  
        let tabular = this.listTabular.find(e => e.tabular.second.start == second);
        if(second == 13 * (this.page-1)){
            for(let i = 0; i < this.listTabular.length; i++){
                if(this.listTabular[i].tabular.second.end >= 13 * (this.page-1) && this.listTabular[i].tabular.second.start <= 13 * (this.page-1)){
                    tabular = this.listTabular[i];
                }
            }
        }
        if (tabular) {
            if(tabular && tabular.tabular.second.start <= 13 * (this.page-1) && tabular.tabular.second.end >= 13 * (this.page-1) && second == 13 * (this.page-1)){
                let length = tabular.tabular.second.old_end + 1 - 13 * (this.page-1);
                length > 13 ? length = 13 : length;
                return length*100+'%'
            } else if(tabular && tabular.tabular.second.end >= (this.page * 13) -1){
                let length = this.page * 13 - tabular.tabular.second.start;
                return length*100+'%'
            }else{
                let length = tabular.tabular.second.old_end + 1 - tabular.tabular.second.start;
                length > 13 ? length = 13 - tabular.tabular.second.start : length;
                return length*100+'%'
            }
        } else {
            if (!index) {
                tabular = this.listTabular.find(e => e.tabular.second.start < second && e.tabular.second.old_end >= second);
                if (tabular) {
                    let length = tabular.tabular.second.old_end + 1 - second;
                    return length*100+'%'
                }
            }
            return '100%'
        }
    },
    getCaputureWidth(time){
      setTimeout(function () {
            const caputure = document.getElementsByClassName('capture-second')[0];
            if(caputure && caputure.offsetWidth){
                this.caputureWidth = document.getElementsByClassName('capture-second')[0].offsetWidth;
                let target = document.getElementsByClassName('second-plus--add-mark');
                for(let i = 0; i < target.length; i++){
                    target[i].style.width = `${this.caputureWidth}px`;
                }
            }
        },time)
    },
    checkXss(content) {
      let re = /^[^<>&";]+$/;
      return re.test(content);
    },
    checkNaturalNumber(number) {
      let re = /^(0|([1-9]\d*))$/;
      return re.test(number);
    },
    setStartEndImage(start = 0) {
      if (this.$props.videoImage) {
        let image_show = 0;
        if (this.page == 1) image_show = 13;
        if (this.page > 1) image_show = 13;
        let duration = this.$props.videoImage.duration;
        let url = this.$props.videoImage.image_url;
        let interaction_rank = this.$props.videoImage.interaction_rank;
        let listRank2 = interaction_rank["2_rank"];
        let listRank3 = interaction_rank["3_rank"];
        let listRank4 = interaction_rank["4_rank"];
        this.duration = duration;
        this.start = start;
        this.end = start + image_show - 1;

        this.isIconNext = !(duration <= image_show - 1 || this.end >= duration);

        this.isIconPrev = this.page > 1;

        this.listImage = [];
        for (let i = this.start; i <= this.end; i++) {
          let second = {};
          let image_url = url + i + ".jpg";
          let rank_2 = {};
          let rank_3 = {};
          let rank_4 = {};
          for (let item in listRank2) {
            if (listRank2[item].second == i) {
              listRank2[item].interaction_count = numberWithCommas(
                listRank2[item].interaction_count
              );
              rank_2 = listRank2[item];
            }
          }
          for (let item in listRank3) {
            if (listRank3[item].second == i) {
              listRank3[item].interaction_count = numberWithCommas(
                listRank3[item].interaction_count
              );
              rank_3 = listRank3[item];
            }
          }
          for (let item in listRank4) {
            if (listRank4[item].second == i) {
              listRank4[item].interaction_count = numberWithCommas(
                listRank4[item].interaction_count
              );
              rank_4 = listRank4[item];
            }
          }
          second["image_url"] = image_url;
          second["rank_2"] = rank_2;
          second["rank_3"] = rank_3;
          second["rank_4"] = rank_4;
          second["second"] = i;
          if (i <= duration) {
            this.listImage.push(second);
          } else {
            this.listImage.push({});
          }
        }
      }
    },
    changeListImageNext() {
      let ref = this.$refs.imageVideo;
      this.$nextTick(() => {
        ref.style.width = "100%";
      });
      if (this.end < this.duration) {
        let start = 0;
        if (this.page == 1) start = this.start + 13;
        if (this.page > 1) start = this.start + 13;
        this.page += 1;
        this.setStartEndImage(start);
        let lastLine;
        if (this.listTabular.length > 0) {
          lastLine = this.listTabular[this.listTabular.length - 1];
          this.secondsLength = lastLine.tabular.second.end + 1;
        } else this.secondLength = 0;
        this.getTabulars();
      }
    },
    changeListImagePrev() {
      let ref = this.$refs.imageVideo;
      let start = 0;
      if (this.page == 2) {
        start = this.start - 13;
        this.$nextTick(() => {
          ref.style.width = "104%";
          ref.style.left = "2px";
        });
      }
      if (this.page > 2) start = this.start - 13;
      let lastLine;
      this.page -= 1;
      this.setStartEndImage(start);
      if (this.listTabular.length > 0) {
        lastLine = this.listTabular[this.listTabular.length - 1];
        this.secondsLength = lastLine.tabular.second.end + 1;
      } else this.secondLength = 0;
      this.getTabulars();
    },
    choseTimeVideo(second) {
      EventBus.$emit("changeCurrentTime", second);
    },
    editFreeNote(index) {
      let vm = this;
      let divList = [...jQuery("#tabledata .input-free-note")];
      divList.forEach((div, idx) => {
        if (idx == index) {
          let val = jQuery(div).val().trim();
          const lineId = jQuery(div).parent().data("id");
          const cellFlg = jQuery(div).parent().data("cell");
          vm.lineId = lineId;
          vm.cellFlg = cellFlg;
          vm.contents = val;
          if (vm.checkXss(val) && val.length > 0) {
            vm.isLoading = true;
            vm.updateVideoTabularContent();
          }
          if (!vm.checkXss(val) && val.length > 0) {
            vm.$toasted.error('自由記入欄に「<>&";」の文字は使用できません');
            vm.getTabulars();
            return;
          }
          if (val.length == 0) {
            vm.isLoading = true;
            vm.updateVideoTabularContent();
          }
          jQuery(div).trigger("blur");
        }
      });
    },
    editCellNote(index) {
      let vm = this;
      let divList = [...jQuery("#tabledata .input-cell-note")];
      divList.forEach((div, idx) => {
        if (idx == index) {
          let val = jQuery(div).val().trim();
          const lineId = jQuery(div).parent().data("id");
          const cellFlg = jQuery(div).parent().data("cell");
          vm.lineId = lineId;
          vm.cellFlg = cellFlg;
          vm.contents = val;
          if (vm.checkXss(val) && val.length > 0) {
            vm.isLoading = true;
            vm.updateVideoTabularContent();
          }
          if (!vm.checkXss(val) && val.length > 0) {
            vm.$toasted.error('シーンに「<>&";」の文字は使用できません');
            jQuery(div).trigger("blur");
            vm.getTabulars();
            return;
          }
          if (val.length == 0) {
            vm.isLoading = true;
            vm.updateVideoTabularContent();
          }
          jQuery(div).trigger("blur");
        }
      });
    },
    editFirstLine(index, tabular){
        let pageBeforeLineCount = 0;
        if(this.page > 1){
            for(let i = 0; i < this.listTabular.length; i++){
                if(this.listTabular[i].tabular.second.end > 13*(this.page-1) && this.listTabular[i].tabular.second.end < tabular.tabular.second.start && this.listTabular[i].tabular_line_id !== tabular.tabular_line_id){
                    pageBeforeLineCount++;
                }
            }
        }
        let vm = this;
        let divList = [...jQuery(".input-first-line")];
        divList.forEach((div,idx)=>{
            if(this.page <= 1 && idx == index || this.page > 1 && idx == pageBeforeLineCount) {
                let val = jQuery(div).val();
                let endSecond = vm.listTabular[index].tabular.second.end;
                let lineId = vm.listTabular[index].tabular_line_id;
                vm.lineId = lineId;
                vm.endSecond = endSecond;
                
                if (Number(val) > vm.$props.videoImage.duration || !val) {
                    vm.$toasted.error('指定された秒数の行は登録できません');
                    vm.getTabulars();
                    jQuery(div).trigger('blur');
                    return;
                } else {
                    if(vm.checkNaturalNumber(Number(val)) && Number(val) >= 0) {
                        vm.startSecond = Number(val);
                        vm.isLoading = true;
                        vm.updateVideoTabularLine();
                    } else {
                        vm.$toasted.error("開始秒数または終了秒数の設定が誤っています");
                        vm.getTabulars();
                        jQuery(div).trigger('blur');
                        return;
                    }
                }
               
                jQuery(div).trigger('blur')
            }
        })
    },
    editSecondLine(index, tabular){
        let pageBeforeLineCount = 0;
        if(this.page > 1){
            for(let i = 0; i < this.listTabular.length; i++){
                if(this.listTabular[i].tabular.second.end >= 13*(this.page-1) && this.listTabular[i].tabular.second.end <= tabular.tabular.second.start && this.listTabular[i].tabular_line_id !== tabular.tabular_line_id){
                    pageBeforeLineCount++;
                }
            }
        }
        let vm = this;
        let divList = [...jQuery(".input-second-line")];
        divList.forEach((div,idx)=>{
            if(idx == index || this.page > 1 && idx == pageBeforeLineCount) {
                let val = jQuery(div).val();
                let startSecond = vm.listTabular[index].tabular.second.start;
                let lineId = vm.listTabular[index].tabular_line_id;
                vm.lineId = lineId;
                vm.startSecond = startSecond;
                
                if (Number(val) > vm.$props.videoImage.duration || !val) {
                    vm.$toasted.error('指定された秒数の行は登録できません');
                    vm.getTabulars();
                    jQuery(div).trigger('blur');
                    return;
                } else {
                    if(vm.checkNaturalNumber(Number(val)) && Number(val) >= 0) {
                        vm.endSecond = Number(val);
                        vm.isLoading = true;
                        vm.updateVideoTabularLine();
                    } else {
                        vm.$toasted.error("開始秒数または終了秒数の設定が誤っています");
                        vm.getTabulars();
                        jQuery(div).trigger('blur');
                        return;
                    }
                }
               
                jQuery(div).trigger('blur')
            }
        })
        
    },
    average(array) {
      try {
        if (Array.isArray(array)) {
          let arrayLength = array.length;
          let reducer = (prevValue, currentValue) => prevValue + currentValue;
          let number = Number(array.reduce(reducer) / arrayLength).toFixed(2);
          return number.substring(0);
        } else return 0;
      } catch (error) {
        return 0;
      }
    },
    totalAvg(num1, num2, num3) {
      let total = (Number(num1) + Number(num2) + Number(num3)).toFixed(2);
      return total.substring(0);
    },
    getListInteractionPerSecond(start, end, type) {
      let result = [];
      for (let i = start; i <= end; i++) {
        if (Object.keys(this.reportVideo).length) {
          if (this.reportVideo.tally_result[i])
            result.push(Math.abs(this.reportVideo.tally_result[i][type]));
          else result.push(0);
        } else {
          result.push(0);
        }
      }
      return result;
    },
    addTabularLine(second) {
      this.saveVideoTabularLine(second)
    },
    deleteTabularLine(tabular){
        this.lineId = tabular.tabular_line_id;
        this.startSecond = tabular.tabular.second.start;
        this.endSecond = tabular.tabular.second.end;
        this.status = 1;
        this.deleteVideoTabularLine();
        this.getCaputureWidth(300);
    },
    // api
    saveVideoTabularLine(index) {
      this.startSecond = index;
      this.endSecond = index;
      let url = this.$router.resolve({
        name: "api.video-tabular-line.register",
      }).href;
      let params = {
        video_id: this.videoImage.video_id,
        start_second: this.startSecond,
        end_second: this.endSecond,
      };
      this.$http2.post(
        url,
        params,
        () => {
          this.getTabulars();
        },
        () => {}
      );
    },
    updateVideoTabularLine() {
      let query = {
        id: this.lineId,
      };
      let url = this.$router.resolve({
        name: "api.video-tabular-line.update",
        params: query,
      }).href;
      let params = {
        start_second: this.startSecond,
        end_second: this.endSecond,
        status: this.status,
        delete_flg : 0
      };
      this.$http2.put(
        url,
        params,
        () => {
          this.getTabulars();
          this.isLoading = false;
        },
        () => {
          this.getTabulars();
          this.isLoading = false;
        }
      );
    },
    deleteVideoTabularLine(){
        let query = {
            id: this.lineId,
        };
        let url = this.$router.resolve({
            name: "api.video-tabular-line.update",
            params: query,
        }).href;
        let params = {
           start_second : this.startSecond,
           end_second : this.endSecond,
           status : this.status,
           delete_flg : 1
        }
        this.$http2.put(
            url,
            params,
            () => {
                this.getTabulars();
                this.isLoading = false;
            },
            () => {
                this.getTabulars();
                this.isLoading = false;
            }
        );
    },
    updateVideoTabularContent() {
      let query = {
        id: this.lineId,
      };
      let url = this.$router.resolve({
        name: "api.video-tabular-contents.update",
        params: query,
      }).href;
      let params = {
        cell_flg: this.cellFlg,
        contents: this.contents,
        user_id: this.currentUser.user_id,
      };
      this.$http2.put(
        url,
        params,
        () => {
          this.isLoading = false;
          this.getTabulars();
        },
        () => {}
      );
    },
    getTabulars() {
      let params = {
        video_id: this.videoId,
      };
      let url = this.$router.resolve({
        name: "api.video-tabular.detail",
      }).href;
      this.$http2.get(
        url,
        params,
        (data) => {
          this.listTabular = data.map((item) => {
            return {
              tabular_line_id: item.tabular_line_id,
              tabular: {
                cell_notes: item.tabular.cell_notes,
                free_notes: item.tabular.free_notes,
                second: {
                  start: item.tabular.second.start,
                  end: item.tabular.second.end,
                  old_end: item.tabular.second.end,
                },
                btn1: this.getListInteractionPerSecond(
                  item.tabular.second.start,
                  item.tabular.second.end,
                  "btn1"
                ),
                btn2: this.getListInteractionPerSecond(
                  item.tabular.second.start,
                  item.tabular.second.end,
                  "btn2"
                ),
                btn3: this.getListInteractionPerSecond(
                  item.tabular.second.start,
                  item.tabular.second.end,
                  "btn3"
                ),
              },
            };
          });
          let lastLine = this.listTabular[this.listTabular.length - 1];
          this.secondsLength = lastLine.tabular.second.end + 1;
          this.getNoLineId(data);
      },()=>{
        this.getNoLineId(this.listTabular);
      });
    },
    getlistTabular(data) {
      this.listTabular = data;
      if (data.length) {
        let lastLine = data[data.length - 1].tabular.second.end;
        this.secondsLength = lastLine + 1;
      }
    },
    getNoLineId(data){
        this.isLineSeconds = [];
        this.noLineSeconds = [];
        if(data.length) {
            let start = 0;
            let end = 0;
            for(let i = 0; i < data.length; i++){
                start = data[i].tabular.second.start;
                end = data[i].tabular.second.end;
                for(let j = start; j <= end; j++){
                    this.isLineSeconds.push(j);
                }
            }
        } else {
            for(let i = 0; i <= this.duration; i++) {
                this.noLineSeconds.push(i);
            }
        }
        if(this.isLineSeconds.length){
            let lineSecondsArray = [];
            for(let i = 0; i <= this.duration; i++){
                lineSecondsArray.push(i);
            }
            this.noLineSeconds = lineSecondsArray.filter(i => this.isLineSeconds.indexOf(i) == -1)
        }
        this.getCaputureWidth(0)
    }
  },
  computed: {
    maxValueBtn1Interaction() {
      let arr = this.listTabular.map((item) =>
          Number(this.average(item.tabular.btn1))
      );
      return Number(Math.max(...arr)).toFixed(2);
    },
    maxValueBtn2Interaction() {
      let arr = this.listTabular.map((item) =>
          Number(this.average(item.tabular.btn2))
      );
      return Number(Math.max(...arr)).toFixed(2);
    },
    maxValueBtn3Interaction() {
      let arr = this.listTabular.map((item) =>
          Number(this.average(item.tabular.btn3))
      );
      return Number(Math.max(...arr)).toFixed(2);
    },
    maxBtn1Interaction() {
      let arr = this.listTabular.map((item) =>
        Number(this.average(item.tabular.btn1))
      );
      let max = Math.max(...arr);
      let index = arr.indexOf(max);
      let indices = [];
      while (index != -1) {
        indices.push(index);
        index = arr.indexOf(max, index + 1);
      }
      return indices;
    },
    maxBtn2Interaction() {
      let arr = this.listTabular.map((item) =>
        Number(this.average(item.tabular.btn2))
      );
      let max = Math.max(...arr);
      let index = arr.indexOf(max);
      let indices = [];
      while (index != -1) {
        indices.push(index);
        index = arr.indexOf(max, index + 1);
      }
      return indices;
    },
    maxBtn3Interaction() {
      let arr = this.listTabular.map((item) =>
        Number(this.average(item.tabular.btn3))
      );
      let max = Math.max(...arr);
      let index = arr.indexOf(max);
      let indices = [];
      while (index != -1) {
        indices.push(index);
        index = arr.indexOf(max, index + 1);
      }
      return indices;
    },
    maxAvgInteraction() {
      let arr = this.listTabular.map(
        (item) =>
          Number(this.average(item.tabular.btn1)) +
          Number(this.average(item.tabular.btn2)) +
          Number(this.average(item.tabular.btn3))
      );
      let max = Math.max(...arr);
      let index = arr.indexOf(max);
      let indices = [];
      while (index != -1) {
        indices.push(index);
        index = arr.indexOf(max, index + 1);
      }
      return indices;
    },
    interactionText() {
      let interactionText = {};
      for (let i = 1; i <= this.NUMBER_BUTTON; i++) {
        if (
          this.videoDetail[`interaction_btn_${i}`] &&
          Object.keys(this.videoDetail[`interaction_btn_${i}`]).length
        ) {
          interactionText[`btn${i}`] =
            this.videoDetail[`interaction_btn_${i}`].text;
        }
      }
      return interactionText;
    },
    interactionColor() {
      let interactionColor = {};
      for (let i = 1; i <= this.NUMBER_BUTTON; i++) {
        if (
          this.videoDetail[`interaction_btn_${i}`] &&
          Object.keys(this.videoDetail[`interaction_btn_${i}`]).length
        ) {
          interactionColor[`btn${i}`] =
            this.videoDetail[`interaction_btn_${i}`].color;
        }
      }
      return interactionColor;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../../../styles/_top_detail.scss";
.loader {
  border: 2px solid #49ab94;
  border-radius: 50%;
  border-top: 2px solid #ffffff;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.dot-color {
  display: inline-block;
  margin-right: 4px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.col-edit-free-notes {
  width: 40%;
}

@media screen and (max-width: 1200px) {
  #tabledata td {
    span {
      font-size: 10px;
    }
  }

  .col-edit-free-notes {
    width: 30%;
  }
}

::v-deep .tooltip-content {
  transform: translateY(2px);
}
</style>