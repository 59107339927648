<template>
  <div>
    <highcharts
      id="chart-container-1"
      class="mt-2"
      :options="chartOptions"
      ref="lineChartSummary"
      :constructor-type="'chart'"
    ></highcharts>
  </div>
</template>
<script>
import { Chart } from "highcharts-vue";
import { polarRadarChartOption } from "@/commons/charts";
const jQuery = require("jquery");
import EventBus from "@/commons/event_bus";
import { formatDataRadarChart } from "@/commons/charts";
import { numberWithCommas } from "@/commons/helpers";
import { LABEL_TYPE_REACTION } from "@/commons/constants";

export default {
  props: {
    seriesData: Array,
    selectedId: String,
    isOnlyOne: { type: Boolean, default: false },
    labelPercent: { type: Boolean, default: true },
    videoDetail: Object,
  },
  components: {
    highcharts: Chart,
  },
  watch: {
    seriesData: {
      handler: function () {
        this.initSeriesData();
        while (this.chart.series.length > 0) this.chart.series[0].remove(false);
        this.chartOptions.series.forEach((serie) => {
          this.chart.addSeries(serie, false);
        });
        this.$nextTick(() => {
          this.chart.redraw();
        });
      },
      deep: true,
    },
    selectedId: function () {
      this.initSeriesData();
      this.chart.series.forEach((serie) => {
        serie.update(
          this.chartOptions.series.find((e) => e.name == serie.name),
        );
      });
      this.chart.redraw();
    },
  },
  computed: {
  },
  data() {
    let vm = this;
    let currentSelected = 0;
    let prevSelected = 0;
    let line, image, image2, clickX, clickY;
    let currentWidth;
    let chartOptions = JSON.parse(JSON.stringify(polarRadarChartOption));
    const COLOR = [
        this.videoDetail?.interaction_btn_1?.color,
        this.videoDetail?.interaction_btn_2?.color,
        this.videoDetail?.interaction_btn_3?.color,
    ]
    const selectedData = this.seriesData.find(item => item.id == this.selectedId)
    return {
      chart: null,
      currentSelected,
      prevSelected,
      line,
      currentWidth,
      image,
      image2,
      clickX,
      clickY,
      prevPart: 1,
      selectedData,
      chartOptions: {
        ...chartOptions,
        chart: {
          polar: true,
          height: window.innerWidth > 1200 ? 410 : 300,
          animation: false,
          width: window.innerWidth > 1200 ? 410 : 300,
          marginTop: 20,
        },
        xAxis: {
          lineColor: "#EDF7F4",
          lineWidth: 8,
          categories: [1, 2, 3],
          tickmarkPlacement: "on",
          labels: {
            align: "center",
            distance: 25,
            formatter() {
              const interactionButtonInfo = vm.$props.videoDetail[`interaction_btn_${ this.value }`];
              if(interactionButtonInfo) {
                return `<span class="label-chart-polar ${'label-chart-polar-' + this.value}"
                                    style="
                                        color: ${ interactionButtonInfo?.color };
                                      ">
                                     ${ interactionButtonInfo?.text ?? "" }
                              <span class="label-chart-polar-number text-center" number="${ this.value }">
                                  ${ selectedData
                                  ? numberWithCommas(selectedData.data[this.value - 1])
                                  : 0 }
                              </span>
                        </span>`;
              } else return '';
            },
            useHTML: true,
          },
          gridLineDashStyle: "dash",
          gridLineColor: "#EDF7F4",
        },
      },
      positionTooltip: 0,
      COLOR,
      LABEL_TYPE_REACTION,
    };
  },
  methods: {
    selectedChart(name) {
      EventBus.$emit("selectedChart", name);
    },
    handleMouseOver(e) {
      if (e.target.name && !this.isOnlyOne) {
        let name = e.target.name;

        let series = this.chart.series;
        series.forEach((serie) => {
          if (serie.name == name) {
            // serie.marker.enabled = true;
            serie.data.forEach((item) => {
              item.marker ? (item.marker.enabled = true) : true;
            });
            serie.options.type = "area";
            serie.options.color = "#000";
            serie.options.opacity = 1;
            serie.update(serie.options);
          }
        });

        if (name) {
          this.$emit("addClassActive", name);
        }
        this.chart.redraw();
      }
    },

    handleMouseOut(e) {
      let vm = this;
      if (e.target.name && !this.isOnlyOne) {
        let name = e.target.name;
        if (name) {
          let series = this.chart.series;
          series.forEach((serie) => {
            if (serie.name !== vm.selectedId) {
              // serie.marker.enabled = false;
              serie.data.forEach((item) => {
                item.marker ? (item.marker.enabled = false) : true;
              });
              serie.options.type = "line";
              serie.options.color = "#EBEBEB";
              serie.options.opacity = 1;
              serie.update(serie.options);
            }
          });
          //remove class active
          this.$emit("removeClassActive", name);
        }
      }

      this.chart.redraw();
    },
    initSeriesData() {
      let vm = this;
      let series = [];

      const selectedData = this.selectedId
        ? this.seriesData.find((item) => item.id == this.selectedId)
        : this.seriesData[0];
      let list_avg_id = ["avg", "avg-0-15", "avg-16-30", "avg-31-60", "avg-61"];
      this.seriesData.forEach((serieData) => {
        let { data, id } = serieData;
        let active = this.isOnlyOne || this.selectedId == id;
        let serie = {
          id: id,
          name: serieData.id,
          data: formatDataRadarChart(data, this.COLOR),
          events: {
            click: function () {
              let ids;
              if (list_avg_id.includes(this.name)) {
                ids = this.name;
              } else {
                ids = this.name.split("-")[1];
              }
              let selected = ids;
              vm.selectedChart(selected);
            },
            mouseOver: vm.handleMouseOver,
            mouseOut: vm.handleMouseOut,
          },
          marker: {
            enabled: false,
          },
          type: "line",
          color: "#EBEBEB",
          animation: false,
        };

        if (active) {
          serie.data.map((e) => {
            e.marker = { enabled: true };
            return e;
          });
          serie.type = "area";
          serie.color = "#000";
          serie.opacity = 1;
          serie.zIndex = 1;
        } else {
          serie.data.map((e) => {
            e.marker = { enabled: false };
            return e;
          });
          serie.zIndex = 2;
        }
        series.push(serie);
      });
      this.chartOptions.series = series;
      const numberLabels = jQuery(".label-chart-polar-number");
      this.$nextTick(() => {
        numberLabels.each((index) => {
          if (index >= 0 && index <= 2 && selectedData && selectedData.data)
            jQuery(numberLabels[index]).text(
                numberWithCommas(Math.round(selectedData.data[index] * 10) / 10) +
                (this.labelPercent ? "%" : "")
            );
        });
      });
      //
    },
  },
  mounted() {
    this.chart = this.$refs.lineChartSummary.chart;
    this.$emit("setChart", this.chart);
    this.initSeriesData();
    this.chart.redraw();
  },
  created() {
    this.initSeriesData();
  },
};
</script>
<style lang="scss" scoped>
#chart-container-1 {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

::v-deep {
  .label-chart-polar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &.label-chart-polar-2 {
      transform: translateX(15px);
    }

    &.label-chart-polar-3 {
      transform: translateX(-15px);
    }
    
    @media screen and (max-width: 1200px) {
      font-size: 10px;

      &.label-chart-polar-2 {
        transform: translateX(10px);
      }

      &.label-chart-polar-3 {
        transform: translateX(-10px);
      }
    }
  }
}
</style>
