var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.videoSegments.length && _vm.videoDetail.is_csv_downloadable)?_c('div',[_c('div',{staticClass:"chart-segment"},[_c('div',{staticClass:"title"},[_vm._v("セグメント別")]),_c('div',{staticClass:"video-report-area mt-4 mb-15"},[_c('div',{staticClass:"row-3"},[_c('div',{staticClass:"row-3-col-1"},[_c('div',{staticClass:"card video-list step1"},[_c('div',{staticClass:"body"},[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab-item",class:{ active: _vm.isTotalChart },on:{"click":function($event){return _vm.changeTypeChart(true)}}},[_vm._v(" 総インタラクション ")]),_c('div',{staticClass:"tab-split"}),_c('div',{staticClass:"tab-item",class:{ active: !_vm.isTotalChart },on:{"click":function($event){return _vm.changeTypeChart(false)}}},[_vm._v(" 各インタラクション ")])]),_c('div',{staticClass:"group-tag-list"},[_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.videoAttributes),function(attribute){return [_c('div',{key:'attribute-' + attribute.id,staticClass:"tag tag-two",class:{ active: _vm.videoAttributeId == attribute.id },on:{"click":function($event){return _vm.selectAttribute(attribute.id)}}},[_c('div',[_vm._v(_vm._s(attribute.name))])])]})],2),(_vm.videoDetail.is_screening == 1)?_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.videoScreening.items),function(question,num){return [_c('div',{key:'question-' + question.id,staticClass:"tag tag-two",class:{ active: _vm.questionIdSelected == question.id },on:{"click":function($event){return _vm.selectQuestion(question.id)}}},[_c('div',[_vm._v(" Q"+_vm._s(num + 1)+"."+_vm._s(_vm.formatQuestionText(question.question_text))+" ")])])]})],2):_vm._e()]),(_vm.attributeIdSelected)?_c('div',{staticClass:"list-checkbox"},[_vm._l((_vm.attributeValueSelected),function(attribute_value){return [_c('div',{key:'attribute-' + attribute_value.id,staticClass:"list-item item-compare",class:{
                      'active selected':
                        _vm.selectedSeriesId == 'attrValue-' + attribute_value.id,
                    },attrs:{"id":'attrValue-' + attribute_value.id}},[_c('div',{staticClass:"item"},[_c('i',{staticClass:"icon icon-checkbox",class:_vm.attributeValueIdSelected.includes(
                            'attrValue-' + attribute_value.id
                          )
                            ? 'checked'
                            : '',on:{"click":function($event){return _vm.checkedAttributeValue(attribute_value.id)}}}),_c('div',{staticClass:"item-name pl-2",on:{"click":function($event){return _vm.selectAttributeValue(attribute_value.id)}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(attribute_value.value))]),_c('div',{staticClass:"percent"},[_vm._v(" "+_vm._s(attribute_value.count)+" / "+_vm._s(_vm.roundDecimal( _vm.totalCountAttrValue > 0 ? attribute_value.count / _vm.totalCountAttrValue : 0 ))+"% ")])])])])]})],2):_c('div',{staticClass:"list-checkbox"},[_vm._l((_vm.answerValueSelected),function(answer){return [_c('div',{key:'answer-' + Object.keys(answer)[0],staticClass:"list-item item-compare",class:{
                      'active selected':
                        _vm.selectedAnswerId == Object.keys(answer)[0],
                    },attrs:{"id":'answerValue-' + Object.keys(answer)[0]}},[_c('div',{staticClass:"item"},[_c('i',{staticClass:"icon icon-checkbox",class:_vm.answerValueIdSelected.includes(
                            Object.keys(answer)[0]
                          )
                            ? 'checked'
                            : '',on:{"click":function($event){_vm.checkedAnswerValue(Object.keys(answer)[0])}}}),_c('div',{staticClass:"item-name pl-2",on:{"click":function($event){_vm.selectAnswerValue(Object.keys(answer)[0])}}},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(Object.values(answer)[0]))])])])])]})],2),(_vm.showAnswerLimitedMsg)?_c('div',{staticClass:"answer-limited-msg"},[_c('div',[_c('i',{staticClass:"icon icon-close",on:{"click":_vm.closeAnswerLimitedMsg}}),_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_vm._v(" 回答は8つまで選択可能です ")])])]):_vm._e()])])]),_c('segment-chart',{attrs:{"reportSegments":_vm.reportSegments,"attributeValueIdSelected":_vm.attributeValueIdSelected,"allAttributeValueIdSelected":_vm.allAttributeValueIdSelected,"answerValueIdSelected":_vm.answerValueIdSelected,"selectedSeriesId":_vm.selectedSeriesId,"selectedAnswerId":_vm.selectedAnswerId,"seriesData":_vm.seriesData,"allSeriesData":_vm.allSeriesData,"videoImage":_vm.videoImage,"videoDetail":_vm.videoDetail,"isTotalChart":_vm.isTotalChart,"reportScreening":_vm.reportScreening,"videoAttributeId":_vm.videoAttributeId,"questionIdSelected":_vm.questionIdSelected,"answerValueSelected":_vm.answerValueSelected},on:{"addClassActive":_vm.addClassActive,"removeClassActive":_vm.removeClassActive}})],1)])]),_c('text-box',{attrs:{"videoId":_vm.videoDetail.id,"urlGet":_vm.urlApiGetVideoSegmentTabular,"urlPost":_vm.urlApiPostVideoSegmentTabular,"placeholder":_vm.placeHolderTextBox,"classTextbox":_vm.classTextbox}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }