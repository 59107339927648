<template>
  <div v-if="videoSegments.length && videoDetail.is_csv_downloadable">
    <div class="chart-segment">
      <div class="title">セグメント別</div>
      <div class="video-report-area mt-4 mb-15">
        <div class="row-3">
          <div class="row-3-col-1">
            <div class="card video-list step1">
              <div class="body">
                <div class="tabs">
                  <div
                    class="tab-item"
                    :class="{ active: isTotalChart }"
                    @click="changeTypeChart(true)"
                  >
                    総インタラクション
                  </div>
                  <div class="tab-split"></div>
                  <div
                    class="tab-item"
                    :class="{ active: !isTotalChart }"
                    @click="changeTypeChart(false)"
                  >
                    各インタラクション
                  </div>
                </div>
                <div class="group-tag-list">
                  <div class="d-flex flex-wrap">
                    <template v-for="attribute in videoAttributes">
                      <div
                        class="tag tag-two"
                        :key="'attribute-' + attribute.id"
                        :class="{ active: videoAttributeId == attribute.id }"
                        @click="selectAttribute(attribute.id)"
                      >
                        <div>{{ attribute.name }}</div>
                      </div>
                    </template>
                  </div>
                  <div
                    class="d-flex flex-wrap"
                    v-if="videoDetail.is_screening == 1"
                  >
                    <template v-for="(question, num) in videoScreening.items">
                      <div
                        class="tag tag-two"
                        :key="'question-' + question.id"
                        :class="{ active: questionIdSelected == question.id }"
                        @click="selectQuestion(question.id)"
                      >
                        <div>
                          Q{{ num + 1 }}.{{
                            formatQuestionText(question.question_text)
                          }}
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="list-checkbox" v-if="attributeIdSelected">
                  <template v-for="attribute_value in attributeValueSelected">
                    <div
                      class="list-item item-compare"
                      :class="{
                        'active selected':
                          selectedSeriesId == 'attrValue-' + attribute_value.id,
                      }"
                      :key="'attribute-' + attribute_value.id"
                      :id="'attrValue-' + attribute_value.id"
                    >
                      <div class="item">
                        <i
                          class="icon icon-checkbox"
                          :class="
                            attributeValueIdSelected.includes(
                              'attrValue-' + attribute_value.id
                            )
                              ? 'checked'
                              : ''
                          "
                          @click="checkedAttributeValue(attribute_value.id)"
                        ></i>
                        <div
                          class="item-name pl-2"
                          @click="selectAttributeValue(attribute_value.id)"
                        >
                          <div class="name">{{ attribute_value.value }}</div>
                          <div class="percent">
                            {{ attribute_value.count }} /
                            {{
                              roundDecimal(
                                totalCountAttrValue > 0
                                  ? attribute_value.count / totalCountAttrValue
                                  : 0
                              )
                            }}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="list-checkbox" v-else>
                  <template v-for="answer in answerValueSelected">
                    <div
                      class="list-item item-compare"
                      :class="{
                        'active selected':
                          selectedAnswerId == Object.keys(answer)[0],
                      }"
                      :key="'answer-' + Object.keys(answer)[0]"
                      :id="'answerValue-' + Object.keys(answer)[0]"
                    >
                      <div class="item">
                        <i
                          class="icon icon-checkbox"
                          :class="
                            answerValueIdSelected.includes(
                              Object.keys(answer)[0]
                            )
                              ? 'checked'
                              : ''
                          "
                          @click="checkedAnswerValue(Object.keys(answer)[0])"
                        ></i>
                        <div
                          class="item-name pl-2"
                          @click="selectAnswerValue(Object.keys(answer)[0])"
                        >
                          <div class="name">{{ Object.values(answer)[0] }}</div>
                          <!--                                          <div class="percent">平均反応値：</div>-->
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="answer-limited-msg" v-if="showAnswerLimitedMsg">
                  <div>
                    <i
                      class="icon icon-close"
                      @click="closeAnswerLimitedMsg"
                    ></i>
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      回答は8つまで選択可能です
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <segment-chart
            :reportSegments="reportSegments"
            :attributeValueIdSelected="attributeValueIdSelected"
            :allAttributeValueIdSelected="allAttributeValueIdSelected"
            :answerValueIdSelected="answerValueIdSelected"
            :selectedSeriesId="selectedSeriesId"
            :selectedAnswerId="selectedAnswerId"
            :seriesData="seriesData"
            :allSeriesData="allSeriesData"
            :videoImage="videoImage"
            :videoDetail="videoDetail"
            :isTotalChart="isTotalChart"
            :reportScreening="reportScreening"
            :videoAttributeId="videoAttributeId"
            :questionIdSelected="questionIdSelected"
            :answerValueSelected="answerValueSelected"
            @addClassActive="addClassActive"
            @removeClassActive="removeClassActive"
          ></segment-chart>
        </div>
      </div>
    </div>
    <text-box
      :videoId="videoDetail.id"
      :urlGet="urlApiGetVideoSegmentTabular"
      :urlPost="urlApiPostVideoSegmentTabular"
      :placeholder="placeHolderTextBox"
      :classTextbox="classTextbox"
    ></text-box>
  </div>
</template>
<script>
import { numberWithCommas, roundDecimal } from "@/commons/helpers";
import {
  getAttributesPromise,
  getReportScreeningPromiseStep1,
  getReportSegmentRanksPromise,
  getReportSegmentsPromiseStep1,
  getVideoScreeningPromise,
  getVideoSegmentsPromise,
} from "@/commons/api_service";
import { formatSeriesDataSegmentAttrStep1 } from "@/commons/charts";
import SegmentChart from "@/components/TopDetail/Step-1/SegmentChart.vue";
import TextBox from "@/components/TopDetail/TextBox.vue";
import EventBus from "@/commons/event_bus";

// Constant
import { LABEL_TYPE_REACTION } from "@/commons/constants";

const jQuery = require("jquery");

export default {
  name: "VideoSegment",
  components: { SegmentChart, TextBox },
  props: ["videoDetail", "videoImage"],
  data() {
    return {
      attributes: [],
      videoSegments: [],
      reportSegmentRanks: {},
      videoAttributes: {},
      attributeIdSelected: null,
      attributeValueSelected: [],
      allAttributeValueIdSelected: [],
      attributeValueIdSelected: [],
      totalCountAttrValue: 0,
      ranks: [],
      reportSegments: [],
      selectedSeriesId: null,
      seriesData: [],
      allSeriesData: [],
      isTotalChart: true,
      urlApiGetVideoSegmentTabular: "api.video.segment-tabular",
      urlApiPostVideoSegmentTabular: "api.videos.segment-tabular.insert",
      placeHolderTextBox: "テキストが入力できます。",
      classTextbox: "segment-tabular",
      is_stack_bar: 0,
      reportScreening: [],
      videoScreening: [],
      questionIdSelected: null,
      answerValueSelected: [],
      answerValueIdSelected: [],
      selectedAnswerId: null,
      showAnswerLimitedMsg: false,
      NUMBER_BUTTON: LABEL_TYPE_REACTION.length,
    };
  },

  computed: {
    videoAttributeId() {
      return this.attributeIdSelected;
    },
  },
  watch: {
    videoAttributes: function (videoAttributes) {
      if (Object.keys(videoAttributes).length) {
        this.attributeIdSelected = videoAttributes
          ? videoAttributes[Object.keys(videoAttributes)[0]].id
          : null;
      }
    },
    attributeIdSelected(newValue) {
      this.setAttributeValueSelect(newValue);
    },
    questionIdSelected(newValue) {
      this.setAnswerValueSelect(newValue);
    },
    attributeValueSelected() {
      this.getAllSeriesData();
    },
  },
  mounted() {
    this.initDataSegmentReport();
  },
  methods: {
    selectedChart(id) {
      this.selectedAttributeValue(id);
    },
    selectedAnswerChart(id) {
      this.selectedAnswerId = id;
    },
    changeTypeChart(isTotalInteraction) {
      this.isTotalChart = isTotalInteraction;

      let reportScreening = getReportScreeningPromiseStep1({
        video_id: this.videoDetail.id,
        interaction_tally: isTotalInteraction ? 1 : 0,
        buyer_id: this.videoDetail.buyer_id,
      })

      reportScreening.then(data => {
        this.reportScreening = JSON.parse(JSON.stringify(data))[0]
      })
    },
    roundDecimal(value) {
      return roundDecimal(value * 100, 1);
    },
    selectAttribute(videoAttributeId) {
      this.attributeIdSelected = videoAttributeId;
      this.questionIdSelected = null;
    },
    selectQuestion(id) {
      this.questionIdSelected = id;
      this.attributeIdSelected = null;
    },
    selectedAttributeValue(attributeValueId) {
      let attrValue = "attrValue-" + attributeValueId;
      if (this.attributeValueIdSelected.includes(attrValue)) {
        this.selectedSeriesId = attrValue;
      }
    },
    checkedAttributeValue(attributeValueId) {
      let attrValue = "attrValue-" + attributeValueId;
      if (this.attributeValueIdSelected.includes(attrValue)) {
        let index = this.attributeValueIdSelected.indexOf(attrValue);
        if (index > -1) {
          this.attributeValueIdSelected.splice(index, 1);
        }
        if (this.selectedSeriesId == attrValue) {
          this.selectedSeriesId = null;
        }
        let index2 = this.seriesData.findIndex(
          (e) => e.id == "attrValue-" + attributeValueId
        );
        if (index2 > -1) {
          this.seriesData.splice(index2, 1);
        }
      } else {
        this.attributeValueIdSelected.push("attrValue-" + attributeValueId);
        if (this.reportSegments[0] && this.reportSegments[0].data) {
          let reportData = this.reportSegments[0].data;
          let serieData = this.formatSeriesDataSegmentVideo(
            reportData,
            attributeValueId
          );
          this.seriesData.push(serieData);
        }
      }
      this.selectedAnswerId = null;
      this.getReportAttributes();
    },
    checkedAnswerValue(id) {
      if (this.answerValueIdSelected.includes(id)) {
        let index = this.answerValueIdSelected.indexOf(id);
        if (index > -1) {
          this.answerValueIdSelected.splice(index, 1);
        }
        if (this.selectedAnswerId == id) {
          this.selectedAnswerId = null;
        }
      } else {
        if (this.answerValueIdSelected.length == 8)
          this.showAnswerLimitedMsg = true;
        else this.answerValueIdSelected.push(id);
      }
      this.selectedSeriesId = null;
    },
    selectAttributeValue(attributeValueId) {
      let attrValue = "attrValue-" + attributeValueId;
      // let reportData = this.reportSegments[0].data.attribute_value_ids
      if (this.attributeValueIdSelected.includes(attrValue)) {
        if (this.selectedSeriesId == attrValue) {
          this.selectedSeriesId = null;
        } else {
          this.selectedSeriesId = attrValue;
        }
      }
    },
    selectAnswerValue(id) {
      if (this.answerValueIdSelected.includes(id)) {
        if (this.selectedAnswerId == id) {
          this.selectedAnswerId = null;
        } else {
          this.selectedAnswerId = id;
        }
      }
    },
    initDataSegmentReport() {
      if (this.videoDetail && this.videoDetail.is_csv_downloadable) {
        let params = {
          video_id: this.videoDetail.id,
          interaction_tally: 0,
          is_stack_bar: this.is_stack_bar,
          buyer_id: this.videoDetail.buyer_id,
        };
        Promise.all([
          getAttributesPromise(),
          getVideoSegmentsPromise({ video_id: this.videoDetail.id }, true),
          getReportSegmentRanksPromise(params, true),
          getReportSegmentsPromiseStep1(params, true),
          getReportScreeningPromiseStep1({
            video_id: this.videoDetail.id,
            interaction_tally: 1,
            buyer_id: this.videoDetail.buyer_id,
          }),
          getVideoScreeningPromise({ video_id: this.videoDetail.id }),
        ]).then((data) => {
          this.attributes = data[0];
          this.videoSegments = data[1];
          this.reportSegmentRanks = data[2];
          this.reportSegments = data[3];
          this.reportScreening = data[4][0];
          this.videoScreening = data[5];
          this.formatAttributeVideo();
        });
      }
    },
    getAttributeDataById(attribute_id) {
      let attributeData = {};
      if (attribute_id) {
        attributeData = this.attributes.find((e) => e.id == attribute_id);
      }
      return attributeData;
    },
    getAttributeValueDataById(attributeData, attribute_value_id) {
      let attributeValueData = {};
      if (attributeData && attribute_value_id) {
        attributeValueData = attributeData.values.find(
          (e) => e.id == attribute_value_id
        );
      }
      return attributeValueData;
    },
    getReportAttributesById(attribute_id) {
      let attributeData = this.getAttributeDataById(attribute_id);
      if (attributeData && attributeData.values) {
        return this.reportSegmentRanks.segment_ranks.filter((report) =>
          attributeData.values.find((e) => report.attribute_value_id == e.id)
        );
      }
      return [];
    },
    formatAttributeVideo() {
      let videoAttributes = {};
      this.videoSegments.forEach((data) => {
        let attribute_id = data.attribute_id;
        let attributeData = this.getAttributeDataById(attribute_id);
        let attribute_value_id = data.attribute_value_id;
        let attributeValueData = this.getAttributeValueDataById(
          attributeData,
          attribute_value_id
        );
        let attr_values = {};
        if (attributeData && attributeValueData) {
          attr_values["id"] = attribute_value_id;
          attr_values["value"] = attributeValueData.value;
          attr_values["sequence"] = attributeValueData.sequence;
          attr_values["count"] = data.count ? data.count : 0;
          if (attribute_id in videoAttributes) {
            videoAttributes[attribute_id].attr_values.push(attr_values);
            videoAttributes[attribute_id].total_count += data.count
              ? data.count
              : 0;
          } else {
            videoAttributes[attribute_id] = attributeData;
            videoAttributes[attribute_id].attr_values = [];
            videoAttributes[attribute_id].attr_values.push(attr_values);
            videoAttributes[attribute_id].total_count = 0;
            videoAttributes[attribute_id].total_count += data.count
              ? data.count
              : 0;
          }
        }
      });
      this.videoAttributes = videoAttributes;
    },
    setAttributeValueSelect(attribute_id) {
      let attributeValueSelected = [];
      if (attribute_id in this.videoAttributes) {
        attributeValueSelected = this.videoAttributes[attribute_id].attr_values;
        this.attributeValueIdSelected = [];
        this.seriesData = [];
        this.checkedAttributeValue(attributeValueSelected[0].id);
        this.totalCountAttrValue =
          this.videoAttributes[attribute_id].total_count;
        this.$nextTick(() => {
          this.selectedSeriesId = this.attributeValueIdSelected.length
            ? this.attributeValueIdSelected[0]
            : null;
        });
      }
      this.attributeValueSelected = attributeValueSelected;
      this.getReportAttributes();
    },
    setAnswerValueSelect(id) {
      this.answerValueIdSelected = [];
      let videoScreeningSelected = this.videoScreening.items.filter(
        (val) => val.id === id
      );
      if (videoScreeningSelected.length) {
        this.answerValueSelected = videoScreeningSelected[0].answers;
        if (this.answerValueSelected.length) {
          this.selectedAnswerId = Object.keys(this.answerValueSelected[0])[0];
          this.checkedAnswerValue(this.selectedAnswerId);
        }
      }
    },
    getReportAttributes() {
      let rank2 = [];
      let rank3 = [];
      if (
        this.reportSegmentRanks.attribute_ids &&
        this.reportSegmentRanks.attribute_ids.length
      ) {
        let reportAttributeData = this.getReportAttributesById(
          this.attributeIdSelected
        );
        let attributeData = this.getAttributeDataById(this.attributeIdSelected);
        if (reportAttributeData.length) {
          reportAttributeData.forEach((data) => {
            if (
              this.attributeValueIdSelected.includes(
                "attrValue-" + data.attribute_value_id
              )
            ) {
              let attributeValueData = this.getAttributeValueDataById(
                attributeData,
                data.attribute_value_id
              );
              let top_good = data["2_top"];
              let top_bad = data["3_top"];
              if (attributeValueData) {
                top_good["attribute_value_id"] = data.attribute_value_id;
                top_bad["attribute_value_id"] = data.attribute_value_id;
                top_good["value"] = attributeValueData.value;
                top_bad["value"] = attributeValueData.value;
              }
              if (top_good["interaction_count"]) {
                top_good["interaction_count"] = numberWithCommas(
                  top_good["interaction_count"]
                );
              }
              if (top_bad["interaction_count"]) {
                top_bad["interaction_count"] = numberWithCommas(
                  top_bad["interaction_count"]
                );
              }
              rank2.push(top_good);
              rank3.push(top_bad);
            }
          });
          this.ranks.push(rank2);
          this.ranks.push(rank3);
        }
      }
    },
    formatSeriesDataSegmentVideo(reportData, attributeValueId) {
      let result = [];
      if (reportData) {
        reportData.forEach((data) => {
          let attrData = {};
          attrData = data.values.find(
            (e) => attributeValueId == e.attribute_value_id
          );
          if (attrData) {
            attrData["second"] = data.second;
            attrData["btn1"] = attrData[`btn1`] ? attrData[`btn1`] : 0;
            attrData["btn2"] = attrData[`btn2`] ? attrData[`btn2`] : 0;
            attrData["btn3"] = attrData[`btn3`] ? attrData[`btn3`] : 0;
            result.push(attrData);
          } else {
            attrData = {};
            attrData["second"] = data.second;
            attrData["btn1"] = 0;
            attrData["btn2"] = 0;
            attrData["btn3"] = 0;
            result.push(attrData);
          }
        });
      }
      return formatSeriesDataSegmentAttrStep1(result, attributeValueId);
    },
    addClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
        jQuery("#" + data).addClass("active");
      }
    },
    removeClassActive(data) {
      if (data) {
        jQuery(".list-item").not(".selected").removeClass("active");
      }
    },
    formatQuestionText(str) {
      if (str.length > 16) {
        str = str.slice(0, 16) + "...";
      }
      return str;
    },
    closeAnswerLimitedMsg() {
      this.showAnswerLimitedMsg = false;
    },
    getAllSeriesData() {
      this.allAttributeValueIdSelected = [];
      this.allSeriesData = [];
      this.attributeValueSelected.map((attr) => {
        this.allAttributeValueIdSelected.push("attrValue-" + attr.id);
        let serieData = this.formatSeriesDataSegmentVideo(
          this.reportSegments[0]?.data,
          attr.id
        );
        this.allSeriesData.push(serieData);
      });
    },
  },

  created() {
    EventBus.$on("selectedChart", this.selectedChart);
    EventBus.$on("selectedAnswerChart", this.selectedAnswerChart);
  },
  beforeDestroy() {
    EventBus.$off("selectedChart", this.selectedChart);
    EventBus.$off("selectedAnswerChart", this.selectedAnswerChart);
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variable";
.chart-segment {
  margin-top: 32px;
  .title {
    @include font(xxl);
    font-weight: bold;
    color: #333;
  }
  .video-report-area {
    height: 353px;
    .video-list {
      width: 100%;
      height: 353px;
      display: flex;
      flex-direction: column;
      position: relative;
      @include font(s);
      justify-content: space-between;
      .footer {
        width: 100%;
        height: 28px;
        border-top: 1px solid #ebebeb;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .setting {
          cursor: pointer;
          color: #49ab94;
        }
      }
      .body {
        &::-webkit-scrollbar {
          width: 2px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: white;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $black;
        }
        direction: rtl;
        overflow-y: auto;
        //padding-bottom: 8px;
      }
      //.setting {
      //  justify-content: end;
      //  max-height: 28px;
      //  font-weight: 700;
      //  font-size: 10px;
      //  line-height: 16px;
      //  color: #49AB94;
      //  padding: 6px 12px;
      //  margin-top: auto;
      //  border: 1px solid #EBEBEB;
      //  border-bottom-left-radius: 8px;
      //  border-bottom-right-radius: 8px;
      //  cursor: pointer;
      //}
      .tabs {
        direction: ltr;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 21px 20px 0;
        .tab-item {
          cursor: pointer;
          color: $middle-black;
          padding-left: 8px;
          padding-right: 8px;
          font-weight: 400;
          &.active {
            color: $black;
            font-weight: 700;
          }
          &:last-child {
            padding-left: 8px;
          }
        }
        .tab-split {
          width: 1px;
          background-color: $light-black;
          height: 8px;
        }
      }
      .group-tag-list {
        display: flex;
        flex-direction: column;
        direction: ltr;
        padding: 20px;
        border-top: 0;
        .tag {
          font-family: "Noto Sans JP";
          margin-right: 10px;
          margin-top: 10px;
          height: 40px;
        }
      }
      .list-checkbox {
        display: flex;
        flex-wrap: wrap;
        direction: ltr;
        cursor: pointer;
        align-items: center;
        .list-item {
          display: flex;
          padding: 12px 20px;
          max-width: 50%;
          flex: 0 0 50%;
          box-sizing: border-box;
          &.active {
            background-color: $light-green;
          }
          .name {
            color: #333333;
            font-size: 12px;
            line-height: 19px;
          }
          .percent {
            @include font(xs);
            line-height: 8px;
            color: $middle-black;
          }
          .percent-good {
            @include font(xs);
            color: $green;
            display: flex;
            align-items: center;
            .dot-good {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $green;
            }
          }
          .percent-bad {
            @include font(xs);
            color: $red;
            display: flex;
            align-items: center;
            .dot-bad {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: $red;
            }
          }
          &.item-compare {
            justify-content: space-between;
            .item {
              width: 100%;
              display: flex;
              align-items: center;
              .item-name {
                flex: 1 1;
              }
            }
          }
        }
      }
      .answer-limited-msg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 8px;

        div {
          position: relative;
          color: #333333;
          font-size: 16px;
          line-height: 22px;
          width: 380px;
          height: 200px;
          background-color: #ffffff;
          border: 1px solid #ebebeb;
          border-radius: 8px;
        }

        i {
          position: absolute;
          cursor: pointer;
          top: 20px;
          right: 20px;
          z-index: 1;
        }
      }
    }
  }
}
.group-list-segment {
  .card-segment {
    height: 100%;
    .data {
      @include font(xxl);
      font-weight: bold;
    }
  }
}
</style>