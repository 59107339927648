<template>
  <div class="row" v-show="Object.keys(video).length">
    <div class="col-13 mr-0 outline-area" v-if="!$store.getters.isLoadingApi">
      <div class="group-top-detail">
        <div class="td-title-left">
          <div class="name-video font-bold">{{ video.name }}</div>
          <div
            class="download-report-csv ml-5"
            v-if="video.is_csv_downloadable"
          >
            <div
              class="btn btn-download btn-primary-outline"
              @click="downloadReportCSV"
            >
              <i class="icon icon-small-download mr-1"></i>
              <div>数値Excel</div>
            </div>
          </div>
          <div class="download-report-csv tooltip_custom_hover ml-2" v-else>
            <div class="btn btn-download btn-primary-outline">
              <i class="icon icon-small-download mr-1"></i>
              <div>数値Excel</div>
            </div>
            <span class="exclaimation_tooltip title_tooltip"
              >数値CSVは調査期間終了日翌日からダウンロードできます</span
            >
          </div>
          <div
            class="btn btn-download btn-primary-outline ml-2 article_dl"
            @click="handleDownloadCSV"
          >
            <i class="icon icon-small-download mr-1"></i>
            <div>動画DL/csv</div>
          </div>
        </div>
        <div class="td-title-right" @click="closeTab">
          <i class="icon icon-close"></i>
        </div>
      </div>

      <Video
        v-if="Object.keys(video).length"
        :videoDetail="video"
        :reportVideo="report_summary"
        :chartType="chartType"
        :videoImage="videoImages[0]"
        :is25percent="is25percent"
        :selectChartType="selectChartType"
        :dataReportCognition="dataReportCognition"
        :dataBuyerNorm="dataBuyerNorm"
        :dataReportNorm="dataReportNorm"
        :totalInteractionBtn1="total_btn1"
        :totalInteractionBtn2="total_btn2"
        :totalInteractionBtn3="total_btn3"
        :youtubeAudienceRating="youtube_audience_rate"
      ></Video>
      <video-capture
        :videoDetail="video"
        :videoImage="videoImages[0]"
        :reportVideo="report_summary"
        :tabulars="listTabular"
      ></video-capture>

      <comment-option
        v-if="Object.keys(video).length && video.is_comment_csv == 1"
        :videoDetail="video"
        :videoImage="videoImages[0]"
        :reportVideo="report_summary"
        :comments="comments"
        :keyword="keyword"
        :commentNotFoundMsg="commentNotFoundMsg"
        :attributeIds="attributeIds"
        :attributeIdSelected="attributeIdSelected"
        :scenes="scenes"
        :attributeValueCommentCount="attributeValueCommentCount"
        :isCsvCommentUploading="isCsvCommentUploading"
      >
      </comment-option>
      <text-box v-if="Object.keys(video).length && video.is_comment_csv == 1"
        :videoId="video.id"
        :urlGet="urlApiGetCommentTabular"
        :urlPost="urlApiPostCommentTabular"
        :placeholder="placeHolderTextBox"
        :classTextbox="classCommentTextBox "
        :idTextBox="'comment-text-box'"
      >
      </text-box>

      <second-ranking
        :videoDetail="video"
        :videoImage="videoImages[0]"
      ></second-ranking>

      <text-box
        :videoId="video.id"
        :urlGet="urlApiGetVideoTabular"
        :urlPost="urlApiPostVideoTabular"
        :placeholder="placeHolderTextBox"
        :classTextbox="classTextbox"
      ></text-box>

      <video-segment
        v-if="Object.keys(video).length && video.is_csv_downloadable"
        :videoDetail="video"
        :videoImage="videoImages[0]"
      ></video-segment>
    </div>
    <div class="loader" v-else></div>
  </div>
</template>

<script>
import Video from "@/components/TopDetail/Step-1/Video.vue";
import VideoCapture from "@/components/TopDetail/Step-1/VideoCapture.vue";
import SecondRanking from "@/components/TopDetail/Step-1/SecondRanking.vue";
import VideoSegment from "@/components/TopDetail/Step-1/VideoSegment.vue";
import CommentOption from "@/components/TopDetail/Step-1/CommentOption.vue";
import TextBox from "@/components/TopDetail/TextBox.vue";
import {
  getVideoDetail,
  listimageVideoStep1,
  getVideoReportStep1,
  getVideoReportCognition,
  getSummaryNormData,
  getYoutubeAudienceRating,
  listVideoComments,
  uploadCsvComment,
} from "@/commons/api_service";
import EventBus from "@/commons/event_bus";
import i18n from "@/i18n";
import {
  backgroundColorInteraction,
  chartTypeStep1 as chartType,
  colorInteraction,
} from "@/commons/constants";

export default {
  name: "TopDetail",
  props: {},
  components: {
    VideoCapture,
    SecondRanking,
    Video,
    VideoSegment,
    TextBox,
    CommentOption,
  },
  data() {
    return {
      video: {},
      videoImages: [],
      report_summary: {},
      project_report_summary: null,
      chartType: chartType,
      interactionTally: 0,
      chartTypeValue: 1,
      is25percent: false,
      dataReportCognition: null,
      selectChartType: chartType[0],
      dataBuyerNorm: null,
      dataReportNorm: null,
      interaction_max: 0,
      interaction_avg: 0,
      listTabular: [],
      total_btn1: 0,
      total_btn2: 0,
      total_btn3: 0,
      tih: {
        total: null,
        btn1: null,
        btn2: null,
        btn3: null,
        "1_2_interaction": null,
        "1_3_interaction": null,
        "2_3_interaction": null,
      },
      tih_percent: {
        total: null,
        btn1: null,
        btn2: null,
        btn3: null,
        "1_2_interaction": null,
        "1_3_interaction": null,
        "2_3_interaction": null,
      },
      tia: {
        total: null,
        btn1: null,
        btn2: null,
        btn3: null,
        "1_2_interaction": null,
        "1_3_interaction": null,
        "2_3_interaction": null,
      },
      tia_percent: {
        total: null,
        btn1: null,
        btn2: null,
        btn3: null,
        "1_2_interaction": null,
        "1_3_interaction": null,
        "2_3_interaction": null,
      },
      youtube_audience_rate: {},
      urlApiGetVideoTabular: "api.video.top-tabular",
      urlApiPostVideoTabular: "api.create.video.top-tabular",
      urlApiGetCommentTabular : 'api.video.comment-tabular',
      urlApiPostCommentTabular :'api.create.video.comment-tabular',
      placeHolderTextBox: "テキストが入力できます。",
      classTextbox: "top-tabular",
      classCommentTextBox : 'comment-tabular',
      isStackBar: 0,
      step: 1,
      comments: {},
      keyword: "",
      attributeIds: "",
      scenes: "",
      attributeIdSelected: null,
      commentNotFoundMsg: "",
      attributeValueCommentCount: null,
      csvDownload: false,
      isCsvCommentUploading: false,
    };
  },
  methods: {
    handleChangeTypeChart(payLoad) {
      this.chartTypeValue = payLoad.typeValue;
      this.interactionTally = payLoad.interaction_tally;
      chartType.forEach((data) => {
        if (data.value == payLoad.typeValue) {
          this.selectChartType = data;
        }
      });
      if (this.chartTypeValue == 1 || this.chartTypeValue == 2) {
        this.is25percent = false;
        if (this.chartTypeValue == 2) {
          this.isStackBar = 0;
        }
        this.loadSummary();
      }
      if (this.chartTypeValue == 3) {
        this.is25percent = true;
        this.loadSummary();
      }
      if (this.chartTypeValue == 4 || this.chartTypeValue == 5) {
        this.is25percent = false;
        this.loadReportCognition();
      }

      if (this.chartTypeValue == 6) {
        this.is25percent = false;
        this.loadSummaryNormData();
      }
    },
    handleDownloadCSV() {
      let id = this.video.id;
      if (id) {
        let url = this.$router.resolve({
          name: "api.videos.download_csv",
          params: { id: id },
        }).href;
        this.$http2.downloadCSV(
          url,
          { filename: `video_info_${id}.csv` },
          () => {}
        );
      }
    },
    downloadReportCSV() {
      if (this.video.is_csv_downloadable) {
        let id = this.video.id;
        let url = this.$router.resolve({
          name: "api.videos.report_file",
          params: { id: id },
        }).href;
        this.$http2.getReportFile(
          url,
          { filename: `2_video_info_${id}.csv` },
          () => {}
        );
      }
    },
    downloadCommentReportCSV() {
      let id = this.video.id;
      let msgErr = "レポートの完成まで数日お待ちください";
      let url = this.$router.resolve({
        name: "api.comment.csv",
        query: { video_id: id },
      }).href;
      this.$http2.downloadCommentCSV(
        url,
        { filename: `comment_info_${id}.csv` },
        () => {},
        (err) => {
          if (err.status) {
            this.$toasted.error(msgErr);
          }
        },
        msgErr
      );
    },
    loadSummary() {
      let paramReport = {
        buyer_id: this.video.buyer_id,
        video_ids: this.video.id,
        interaction_tally: parseInt(this.interactionTally),
        is_stack_bar: this.isStackBar,
      };
      getVideoReportStep1(paramReport, (report) => {
        this.report_summary = report.length
          ? report[0]
          : { ...this.report_summary };
        this.getTabulars();

        // tih
        this.tih.total = this.report_summary.tih.total;
        this.tih.btn1 = this.report_summary.tih.btn1;
        this.tih.btn2 = this.report_summary.tih.btn2;
        this.tih.btn3 = this.report_summary.tih.btn3;
        this.tih[`1_2_interaction`] =
          this.report_summary.tih[`1_2_interaction`];
        this.tih[`2_3_interaction`] =
          this.report_summary.tih[`2_3_interaction`];
        this.tih[`1_3_interaction`] =
          this.report_summary.tih[`1_3_interaction`];

        // tih_percent
        this.tih_percent.total = this.report_summary.tih_percent.total;
        this.tih_percent.btn1 = this.report_summary.tih_percent.btn1;
        this.tih_percent.btn2 = this.report_summary.tih_percent.btn2;
        this.tih_percent.btn3 = this.report_summary.tih_percent.btn3;
        this.tih_percent[`1_2_interaction`] =
          this.report_summary.tih_percent[`1_2_interaction`];
        this.tih_percent[`2_3_interaction`] =
          this.report_summary.tih_percent[`2_3_interaction`];
        this.tih_percent[`1_3_interaction`] =
          this.report_summary.tih_percent[`1_3_interaction`];

        // tia
        this.tia.total = this.report_summary.tia.total;
        this.tia.btn1 = this.report_summary.tia.btn1;
        this.tia.btn2 = this.report_summary.tia.btn2;
        this.tia.btn3 = this.report_summary.tia.btn3;
        this.tia[`1_2_interaction`] =
          this.report_summary.tia[`1_2_interaction`];
        this.tia[`2_3_interaction`] =
          this.report_summary.tia[`2_3_interaction`];
        this.tia[`1_3_interaction`] =
          this.report_summary.tia[`1_3_interaction`];

        // tia_percent
        this.tia_percent.total = this.report_summary.tia_percent.total;
        this.tia_percent.btn1 = this.report_summary.tia_percent.btn1;
        this.tia_percent.btn2 = this.report_summary.tia_percent.btn2;
        this.tia_percent.btn3 = this.report_summary.tia_percent.btn3;
        this.tia_percent[`1_2_interaction`] =
          this.report_summary.tia_percent[`1_2_interaction`];
        this.tia_percent[`2_3_interaction`] =
          this.report_summary.tia_percent[`2_3_interaction`];
        this.tia_percent[`1_3_interaction`] =
          this.report_summary.tia_percent[`1_3_interaction`];

        // Total
        this.total_btn1 = this.report_summary.total_2;
        this.total_btn2 = this.report_summary.total_3;
        this.total_btn3 = this.report_summary.total_4;
        this.tih.total = this.report_summary.tih.total;

      });
    },
    loadReportCognition() {
      let param = {
        buyer_id: this.video.buyer_id,
        video_id: this.video.id,
        interaction_tally: parseInt(this.interactionTally),
        is_stack_bar: this.isStackBar,
      };
      getVideoReportCognition(param, (report) => {
        if (report.length == 0) return false;
        this.dataReportCognition = report[0].data;
      });
    },
    loadSummaryNormData() {
      let params = {
        buyer_id: this.video.buyer_id,
        video_ids: this.video.id,
        is_stack_bar: this.isStackBar,
      };
      getSummaryNormData(params, (data) => {
        if (data.length == 0) return false;
        this.dataBuyerNorm = data[0];
        this.dataReportNorm = data[1];
        // this.loadDataChartNorm();
      });
    },
    gotoURL() {
      this.$router.back();
    },
    getTabulars() {
      let params = {
        video_id: this.video.id,
      };
      let url = this.$router.resolve({
        name: "api.video-tabular.detail",
      }).href;
      this.$http2.get(
        url,
        params,
        (data) => {
          this.listTabular = data.map((item) => {
            return {
              tabular_line_id: item.tabular_line_id,
              tabular: {
                cell_notes: item.tabular.cell_notes,
                free_notes: item.tabular.free_notes,
                second: {
                  start: item.tabular.second.start,
                  end: item.tabular.second.end,
                  old_end: item.tabular.second.end,
                },

                // TODO fake data
                btn1: this.getListInteractionPerSecond(
                  item.tabular.second.start,
                  item.tabular.second.end,
                  "btn1"
                ),
                btn2: this.getListInteractionPerSecond(
                  item.tabular.second.start,
                  item.tabular.second.end,
                  "btn2"
                ),
                btn3: this.getListInteractionPerSecond(
                  item.tabular.second.start,
                  item.tabular.second.end,
                  "btn3"
                ),
              },
            };
          });
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getListInteractionPerSecond(start, end, type) {
      let result = [];
      for (let i = start; i <= end; i++) {
        if (Object.keys(this.report_summary).length) {
          if (this.report_summary.tally_result[i])
            result.push(Math.abs(this.report_summary.tally_result[i][type]));
          else result.push(0);
        } else {
          result.push(0);
        }
      }
      return result;
    },
    loadYoutubeAudienceRating() {
      let youtubeVideoId = this.video.youtube_video_id;
      let query = {
        video_id: this.video.id,
      };
      if (youtubeVideoId !== null) {
        getYoutubeAudienceRating(query, (data) => {
          this.youtube_audience_rate = data.length
            ? data[0]
            : { ...this.youtube_audience_rate };
        });
      } else {
        this.youtube_audience_rate = {};
      }
    },
    closeTab() {
      window.close();
    },
    loadListVideoComments() {
      let param = {};
      let prevComments = this.comments;
      if (this.keyword) {
        param.keywords = this.keyword;
      }
      if (this.attributeIds) {
        param.attribute_value_ids = this.attributeIds;
      }
      if (this.scenes) {
        param.scenes = this.scenes;
      }
      listVideoComments(param, this.video.id, (comments) => {
        this.comments = comments ? comments : {};
        if (!this.comments.all_comment) this.comments = prevComments;
        else this.commentNotFoundMsg = "";
        if (!this.attributeValueCommentCount) {
          this.attributeValueCommentCount =
            this.comments.attribute_value_comment_count;
        }
      });
    },
    updateAttrComment(val) {
      this.attributeIds = val.length ? val.toString() : "";
      if (this.attributeIds) {
        this.commentNotFoundMsg = "該当するコメントがありません。";
      }
      this.loadListVideoComments();
    },
    updateAttrIdSelected(val) {
      this.attributeIdSelected = val;
    },
    updateKeyWordComment(val) {
      this.keyword = val;
      this.commentNotFoundMsg = "キーワードに該当するコメントはありません。";
      this.loadListVideoComments();
    },
    updateSceneComment(val) {
      this.scenes = val.length ? val.toString() : "";
      this.commentNotFoundMsg = "該当するコメントがありません。";
      this.loadListVideoComments();
    },
    isCsvDownload(val){
      this.csvDownload = val
      this.downloadCommentReportCSV();
    },
    uploadCsvCommentToS3(fileInfo) {
      let vm = this;
      uploadCsvComment(this.video.id, fileInfo, function () {
        vm.$toasted.success(i18n?.t('messages.update_success'))
        vm.isCsvCommentUploading = false;
      }, function () {
        vm.$toasted.error(i18n?.t('messages.update_fail'))
        vm.isCsvCommentUploading = false;
      })
    },
  },
  created() {
    EventBus.$on("changeTypeChart", this.handleChangeTypeChart);
    EventBus.$on("updateAttrComment", this.updateAttrComment);
    EventBus.$on("updateAttrIdSelected", this.updateAttrIdSelected);
    EventBus.$on("updateKeyWordComment", this.updateKeyWordComment);
    EventBus.$on("updateSceneComment", this.updateSceneComment);
    EventBus.$on('isCsvDownload', this.isCsvDownload);
    EventBus.$on('uploadCsvComment', this.uploadCsvCommentToS3);
    let id = this.$route.params.video_id;

    getVideoDetail(id).then((videoDetail) => {
      this.video = videoDetail;
      for(let i = 1; i <= 3; i ++) {
        if(this.video[`interaction_btn_${i}`]?.color) {
          this.video[`interaction_btn_${i}`].backgroundColor = backgroundColorInteraction[this.video[`interaction_btn_${i}`].color]?.color
          this.video[`interaction_btn_${i}`].color = colorInteraction[this.video[`interaction_btn_${i}`].color]?.color
        }
      }
      this.loadSummary();
      this.loadReportCognition();
      this.loadYoutubeAudienceRating();
      this.loadListVideoComments();
    });
    let param = {
      video_id: id,
      interaction_tally: 0,
    };
    listimageVideoStep1(param).then((data) => {
      if (data[0]) {
        data[0].interaction_rank["4_rank"] = data[0].interaction_rank["3_rank"];
        data[0].interaction_rank["3_rank"] = data[0].interaction_rank["2_rank"];
        data[0].interaction_rank["2_rank"] = data[0].interaction_rank["1_rank"];
      }

      this.videoImages = data;
    });
  },
  beforeDestroy() {
    EventBus.$off("changeTypeChart", this.handleChangeTypeChart);
    EventBus.$off("updateAttrComment", this.updateAttrComment);
    EventBus.$off("updateAttrIdSelected", this.updateAttrIdSelected);
    EventBus.$off("updateKeyWordComment", this.updateKeyWordComment);
    EventBus.$off("updateSceneComment", this.updateSceneComment);
    EventBus.$off('isCsvDownload', this.isCsvDownload);
    EventBus.$off('uploadCsvComment', this.uploadCsvCommentToS3);
  },
};
</script>
<style lang="scss" scoped>
@import "./../../styles/_top_detail.scss";
::v-deep .title {
  font-size: 24px;
  line-height: 39px;
  font-weight: bold;
}
</style>